export const billingInfoConfig: any = () => ({
  legalName: {
    label: 'Legal Name',
    isVisible: true,
  },
  taxid: {
    label: 'Tax ID',
    isVisible: true,
  },
  closedDate: {
    label: 'Closed Date',
    type: 'date',
    isVisible: true,
  },
  vintageName: {
    label: 'Vintage Name',
    isVisible: true,
  },
  mergedInto: {
    label: 'Merged Into',
    type: 'select',
    placeholder: 'Select an office',
    allowClear: true,
    isVisible: true,
  },
  depositMethod: {
    label: 'Deposit Method',
    isVisible: true,
  },
});
