/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { setSearchVendor } from '../../vendors/vendors-actions';
import { getAllVendors } from '../../vendors/vendors-selectors';

function SearchVendor() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;
  const data = useLocation();
  const vendorDetails: any = data.state;
  const history = useHistory();
  const vendorlist: any = useSelector(getAllVendors)?.sort((a: any, b: any) =>
    a.name?.localeCompare(b.name, 'en', { numeric: true })
  );
  const [vendor, setVendor] = useState<any>();
  useEffect(() => {
    if (vendorlist) {
      setVendor([...vendorlist]);
    }
  }, [vendorlist]);
  useEffect(() => {
    if (vendorDetails) {
      const details = {
        isDefualt: vendorDetails?.isDefualt,
        active: vendorDetails?.active,
        name: vendorDetails?.name,
      };
      form.setFieldsValue(details);
      dispatch(setSearchVendor({ ...details, page: 0, size: 15 }));
      history.replace('/vendor');
    }
  }, [vendorDetails]);

  const onSubmit = (formData) => {
    const { ...data } = formData;
    const searchDetails = {
      name: data?.name ? data?.name : '',
      isDefault: data?.isDefault,
      active: data?.active,
      page: 0,
      size: 15,
    };
    dispatch(setSearchVendor(searchDetails));
  };
  return (
    <div className='search-container'>
      <Form
        form={form}
        layout='vertical'
        className='search-container'
        onFinish={(formData) => {
          onSubmit(formData);
        }}
      >
        <Form.Item key='name' label={'Vendor'} name='name' className='label-text input-field'>
          <Select
            allowClear
            showArrow
            maxTagCount={'responsive'}
            style={{ width: '160px' }}
            showSearch={true}
            placeholder='Select vendor'
            optionFilterProp='children'
            filterOption={(input, option) => {
              return (
                option?.children !== undefined &&
                (option!?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              );
            }}
          >
            {vendor?.map((item, index) => {
              return (
                <Option key={index} value={item?.name}>
                  {item?.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item key='isDefault' label='isDefault' name='isDefault' className='label-text input-field'>
          <Select
            allowClear
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
          />
        </Form.Item>
        <Form.Item key='active' label='active' name='active' className='label-text input-field'>
          <Select
            allowClear
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
          />
        </Form.Item>
        <Form.Item className='search-btn'>
          <Button type='primary' htmlType='submit'>
            SEARCH
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default SearchVendor;
