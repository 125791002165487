import { call, put, select } from 'redux-saga/effects';
import {
  createVendorFailed,
  createVendorSuccess,
  fetchVendors,
  fetchVendorsSuccess,
  searchVendor,
  searchVendorFailed,
  searchVendorSuccess,
} from './vendors-actions';
import { getSearch } from './vendors-selectors';
import { api, showErrorNotification, showSuccessNotification } from '@med-fe/util';

export function* fetchVendorStartWorker({ payload }: any): Generator {
  try {
    let url = `/vendors`;
    const data = yield call(api.get, url);
    yield put(fetchVendorsSuccess(data));
  } catch (e) {
    console.log(e);
    showErrorNotification(null, e);
  }
}

export function* searchVendorStartWorker({ payload }): Generator {
  try {
    const url = `/vendors/search`;
    const data = yield call(api.post, url, payload);
    yield put(searchVendorSuccess(data));
  } catch (e) {
    yield put(searchVendorFailed(e));
    showErrorNotification();
  }
}

export function* createVendorStartWorker({ payload }): Generator {
  try {
    const url = '/vendors';
    const search = yield select(getSearch);
    const data = yield call(payload.id ? api.put : api.post, url, payload);
    showSuccessNotification(`Vendor ${payload.id ? 'updated' : 'created'} successfully`);
    yield put(createVendorSuccess(payload));
    yield put(fetchVendors({}));
    yield put(searchVendor(search));
  } catch (e) {
    yield put(createVendorFailed(e));
    showErrorNotification(null, e);
  }
}
