import { Button, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gerProfileForLocation } from '../../offices/offices-selectors';
import { capitalize } from 'lodash';
import moment from 'moment';
import { createLocation } from '../../offices/offices-actions';
import { profileConfig } from '../configs/profile-config';
import { getWriteTabsPermissions } from '../../../store/user/user-reducer';
import { formItemMap } from '@med-fe/ui';
import { PosEnum } from 'apps/mod/src/models/pos-enum';

function ProfileDetails({ locId }) {
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();
  const data = useSelector((state) => gerProfileForLocation(state, locId));
  const isEditAllowed = useSelector(getWriteTabsPermissions).profile;
  const [posValue, setPosValue] = useState(data?.pos ? data?.pos : null);

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      ...(!!data.openingDate && { openingDate: moment(data.openingDate) }),
      ...(!!data.leaseExpiration && { leaseExpiration: moment(data.leaseExpiration) }),
      ...(!!data.encompassLaunchDate && { encompassLaunchDate: moment(data.encompassLaunchDate) }),
    });
  }, [locId]);

  const onSubmit = (formData: any) => {
    const { openingDate, leaseExpiration, modifiedDate, encompassLaunchDate, ...restData } = formData;
    const payload = {
      ...restData,
      ...{ openingDate: openingDate ? openingDate.format('YYYY-MM-DD') : null },
      ...{ leaseExpiration: leaseExpiration ? leaseExpiration.format('YYYY-MM-DD') : null },
      ...{ encompassLaunchDate: encompassLaunchDate ? encompassLaunchDate.format('YYYY-MM-DD') : null },
      id: locId,
    };
    dispatch(createLocation(payload));
  };

  const onPosSelect = (value) => {
    setPosValue(value);
  };

  return (
    <Form
      form={form}
      layout='vertical'
      className='profile-details-form'
      onFinish={(formData) => {
        onSubmit(formData);
        setIsDisabled(true);
      }}
      onValuesChange={() => setIsDisabled(false)}
    >
      {Object.entries(profileConfig).map(([key, value]: any) => {
        const params = {
          key,
          label: capitalize(value.label),
          name: key,
          rules: value.rules,
          ...(value.type === 'checkbox' && { valuePropName: 'checked' }),
        };
        const formItemParams = {
          ...value,
          ...(key === 'mergedInto' && {
            data: data.mergedInto,
          }),
          ...(key === 'pos' && { onSelect: onPosSelect, data: Object.entries(PosEnum) }),
        };
        return !(key == 'encompassLaunchDate' && (posValue === 'ALE' || posValue === null)) ? (
          <Form.Item
            {...params}
            className={value.type === 'checkbox' ? 'checkbox-item' : ''}
            validateTrigger={['onBlur', 'onSubmit']}
          >
            {formItemMap(formItemParams, !isEditAllowed)}
          </Form.Item>
        ) : null;
      })}

      <Form.Item>
        <Button type='primary' htmlType='submit' className={'btn-form-action'} disabled={!isEditAllowed || isDisabled}>
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ProfileDetails;
