export enum ManagersEnum {
  AV = 'Area VP',
  AM = 'Area Manager',
  RM = 'Regional Vice President',
  DM = 'District Manager',
  GM = 'General Manager',
  AGM = 'Assistant General Manager',
  GMIT = 'General Manager in Training',
  DOPD = 'Director of Practice Development',
}
