import React from 'react';
import { CheckBoxCell } from '@med-fe/ui';

export const profile_columns = [
  {
    title: 'Location number',
    dataIndex: 'locationNumber',
    key: 'action',
    width: 120,
    fixed: 'left',
  },
  {
    title: 'Location name',
    dataIndex: 'locationName',
    key: 'locationName',
    width: 250,
  },
  {
    title: 'External location name',
    dataIndex: 'externalLocationName',
    key: 'externalLocationName',
    width: 260,
  },
  {
    title: 'Located In',
    dataIndex: 'locatedIn',
    key: 'locatedIn',
    width: 260,
  },
  {
    title: 'open date',
    dataIndex: 'openingDate',
    key: 'openingDate',
    width: 100,
  },
  {
    title: 'email',
    dataIndex: 'email',
    key: 'email',
    width: 220,
  },
  {
    title: 'sq. foot.',
    dataIndex: 'squareFootage',
    key: 'squareFootage',
    width: 100,
  },
  {
    title: 'lease exp.',
    dataIndex: 'leaseExpiration',
    key: 'leaseExpiration',
    width: 100,
  },
  {
    title: 'unified',
    dataIndex: 'unified',
    key: 'unified',
    width: 80,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
  {
    title: 'lab onsite',
    dataIndex: 'labOnsite',
    key: 'labOnsite',
    width: 90,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
  {
    title: 'last modified',
    dataIndex: 'modifiedDate',
    key: 'modifiedDate',
    width: 140,
  },
  {
    title: 'POS',
    dataIndex: 'pos',
    key: 'pos',
    width: 160,
  },
  {
    title: 'Encompass Launch Date',
    dataIndex: 'encompassLaunchDate',
    key: 'encompassLaunchDate',
    width: 160,
  },
  {}, // empty column, workaround to have column width fixed
];
