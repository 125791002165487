import { PERMISSIONS } from '../../constants/permissions.constants';

export const isReadAllAllowedHelper = (permissions: string[]) => permissions.includes('read');

export const isWriteAllAllowedHelper = (permissions: string[]) => permissions.includes('write');

export const isReadAllowedHelper = (tabOrPage: string, permissions: string[]) =>
  permissions.includes('read') || PERMISSIONS[tabOrPage].read.every((role) => permissions.includes(role));

export const isReadMapAllowedHelper = (permissions: string[]) =>
  permissions.includes('read') ||
  permissions.includes('write') ||
  ['locations', 'profile', 'finance', 'marketing', 'schedule', 'mvc', 'pro', 'it', 'veh', 'specialty', 'vendor'].some(
    (tab) =>
      PERMISSIONS[tab].read.every((role) => permissions.includes(role)) ||
      PERMISSIONS[tab].write.every((role) => permissions.includes(role))
  );

export const isWriteAllowedHelper = (tabOrPage: string, permissions: string[]) =>
  permissions.includes('write') || PERMISSIONS[tabOrPage].write.every((role) => permissions.includes(role));

export const isAccessDeniedHelper = (permissions: string[]) => !permissions.length;

export const isContactWriteAllowedHelper = (tabOrPage: string, permissions: string[]) =>
  PERMISSIONS[tabOrPage].write.every((role) => permissions.includes(role));
