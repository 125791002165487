import { PHONE_VALIDATION_RULE, ZIP_VALIDATION_RULE, YEAR_VALIDATION_RULE, AMOUNT_VALIDATION_RULE } from '@med-fe/util';

export const locationConfig: any = (isCreateMode = false) => ({
  region: {
    label: 'Region',
    isVisible: true,
    type: 'select',
    placeholder: 'Select a Region',
    edit: true,
    add: true,
  },
  district: {
    label: 'District',
    isVisible: true,
    type: 'select',
    placeholder: 'Select a District',
    edit: true,
    add: true,
  },
  county: {
    label: 'County',
    isVisible: true,
    edit: true,
    add: false,
  },
  locationName: {
    label: 'Location Name',
    isVisible: true,
    type: 'text',
    rules: [{ required: true, message: 'Please enter Location Name' }],
    edit: true,
    add: true,
  },
  locationNumber: {
    label: 'Location Number',
    isVisible: isCreateMode,
    rules: [{ required: true, message: 'Please enter Location Number' }],
    edit: true,
    add: true,
  },
  street: {
    label: 'Street',
    isVisible: true,
    edit: true,
    add: true,
  },
  street2: {
    label: 'Street 2',
    isVisible: true,
    edit: true,
    add: true,
  },
  city: {
    label: 'City',
    isVisible: true,
    edit: true,
    add: true,
  },
  state: {
    label: 'State',
    isVisible: true,
    rules: [{ required: true, message: 'Please enter State' }],
    edit: true,
    add: true,
  },
  zip: {
    label: 'ZIP',
    isVisible: true,
    rules: [ZIP_VALIDATION_RULE],
    edit: true,
    add: true,
  },
  phone: {
    label: 'Phone',
    isVisible: true,
    type: 'phone-field',
    rules: [PHONE_VALIDATION_RULE],
    edit: true,
    add: true,
  },
  fax: {
    label: 'Fax',
    isVisible: true,
    type: 'phone-field',
    rules: [PHONE_VALIDATION_RULE],
    edit: true,
    add: true,
  },
  directDial: {
    label: 'Direct Dial',
    isVisible: true,
    type: 'phone-field',
    rules: [PHONE_VALIDATION_RULE],
    edit: true,
    add: false,
  },
  timezone: {
    label: 'Timezone',
    isVisible: true,
    edit: true,
    add: true,
  },
  ctc: {
    label: 'CTC',
    isVisible: true,
    type: 'checkbox',
    edit: true,
    add: false,
  },
  status: {
    label: 'Status',
    isVisible: true,
    type: 'select',
    edit: true,
    add: true,
  },
  openingDate: {
    //status=pending open
    label: 'Opening Date',
    isVisible: false,
    type: 'date',
    edit: true,
    add: true,
  },
  locationCategory: {
    label: 'Location Category',
    isVisible: true,
    type: 'select',
    edit: false,
    add: true,
  },
  newOfficeYear: {
    //locationCategory=De-Novo
    label: 'New Office Year',
    isVisible: false,
    type: 'text',
    rules: [{ required: true, message: 'please enter New office year' }, YEAR_VALIDATION_RULE],
    edit: false,
    add: true,
  },
  jointVentureYear: {
    //locationCategory=JointVenture
    label: 'Joint Venture Year',
    isVisible: false,
    type: 'text',
    rules: [{ required: true, message: 'please enter Joint Venture year' }, YEAR_VALIDATION_RULE],
    edit: false,
    add: true,
  },
  acquisitionYear: {
    //locationCategory=Acquisition
    label: 'Acquisition Year',
    isVisible: false,
    type: 'text',
    rules: [{ required: true, message: 'please enter Acquisition Year' }, YEAR_VALIDATION_RULE],
    edit: false,
    add: true,
  },
  preAcquisitionAnnualRevenue: {
    //locationCategory=JointVenture, Acquisition
    label: 'Pre-Acquisition Annual Revenue (in $)',
    isVisible: false,
    type: 'text',
    rules: [{ required: true, message: 'please enter Pre-Acquisition Annual Revenue' }, AMOUNT_VALIDATION_RULE],
    edit: false,
    add: true,
  },
  vintageName: {
    //locationCategory=Acquisition
    label: 'Vintage Name',
    isVisible: false,
    type: 'text',
    rules: [{ required: true, message: 'please enter Vintage Name' }],
    edit: false,
    add: true,
  },
  pos: {
    label: 'POS',
    isVisible: true,
    type: 'select',
    edit: false,
    add: true,
  },
  encompassLaunchDate: {
    //pos=Encompass
    label: 'Encompass Launch Date',
    isVisible: false,
    type: 'date',
    edit: false,
    add: true,
  },
  dryEyeTreatment: {
    label: 'Dry Eye Treatment',
    isVisible: true,
    type: 'checkbox',
    edit: false,
    add: true,
  },
});
