import {
  CREATE_VENDOR,
  CREATE_VENDOR_SUCCESS,
  CREATE_VENDOR_FAILED,
  FETCH_VENDORS_SUCCESS,
  FETCH_VENDORS,
  FETCH_VENDORS_FAILED,
  SEARCH_VENDORS,
  SEARCH_VENDORS_SUCCESS,
  SEARCH_VENDORS_FAILED,
  SET_SEARCH_VENDORS,
} from './vendors-actions';

export const VENDORS_STATE_KEY = 'VENDORS_REDUCER';

type TState = {
  vendor: {
    contents: [];
    pages: number;
    total: number;
  };
  search: {
    page: number;
    size: number;
  };
  sorting: {
    field: string;
    order: 'asc';
  };
  loading: boolean;
  error: any;
  vendorDetails: any;
  detailsLoading: boolean;
  removeLoading: boolean;
  importLoading: boolean;
  importFailed: boolean;
  importValidationData: any[];
  totalCount: any;
  successErrorReport: any;
};

const initialState: TState = {
  vendor: {
    contents: [],
    pages: 0,
    total: 0,
  },
  search: {
    page: 0,
    size: 15,
  },
  sorting: {
    field: 'name',
    order: 'asc',
  },
  loading: false,
  error: null,
  vendorDetails: null,
  detailsLoading: false,
  removeLoading: false,
  importLoading: false,
  importFailed: false,
  importValidationData: [],
  totalCount: null,
  successErrorReport: {},
};
export const vendorsReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case FETCH_VENDORS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_VENDORS_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorDetails: payload,
      };
    case FETCH_VENDORS_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case CREATE_VENDOR:
    case CREATE_VENDOR_SUCCESS:
      return {
        ...state,
      };

    case CREATE_VENDOR_FAILED:
      return {
        ...state,
        error: payload,
      };
    case SET_SEARCH_VENDORS:
      return {
        ...state,
        search: { ...state.search, ...payload },
      };
    case SEARCH_VENDORS:
      return {
        ...state,
        loading: payload,
      };
    case SEARCH_VENDORS_SUCCESS:
      return {
        ...state,
        loading: false,
        vendor: payload,
      };
    case SEARCH_VENDORS_FAILED:
    default:
      return state;
  }
};
