import { notification } from 'antd';

export const setNotificationsConfig = () => {
  notification.config({
    top: 50,
    duration: 1.5,
    maxCount: 1,
  });
};

export const showSuccessNotification = (text = 'Updated successfully', duration?: number) => {
  notification.success({
    message: 'Success',
    className: 'toast-container success',
    description: text,
    ...(duration && { duration }),
  });
};

export const showErrorNotification = (text?, e?: any, duration?: number) => {
  if (e?.response?.status === 401) {
    notification.error({
      message: 'Error',
      className: 'toast-container error',
      description: 'Your session has expired, please re-log in',
      duration: 0,
    });
  } else {
    notification.error({
      message: 'Error',
      className: 'toast-container error',
      description: text || e?.response?.data?.reason || 'Something went wrong, please try again',
      ...(duration && { duration }),
    });
  }
};

export const showWarningNotification = (text = '') => {
  notification.warn({
    message: 'Warning!',
    className: 'toast-container warning',
    description: text,
    duration: 0,
  });
};
