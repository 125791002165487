import {
  CLEAR_OFFICE_LIST,
  CREATE_BILLING,
  CREATE_BILLING_FAILED,
  CREATE_BILLING_SUCCESS,
  CREATE_DISTRICT,
  CREATE_DISTRICT_FAILED,
  CREATE_DISTRICT_SUCCESS,
  CREATE_LOCATION,
  CREATE_LOCATION_FAILED,
  CREATE_LOCATION_SUCCESS,
  CREATE_REGION,
  CREATE_REGION_FAILED,
  CREATE_REGION_SUCCESS,
  DELETE_LOCATION,
  DELETE_LOCATION_FAILED,
  DELETE_LOCATION_SUCCESS,
  FETCH_CONTACTS,
  FETCH_CONTACTS_FAIL,
  FETCH_CONTACTS_SUCCESS,
  FETCH_DISTRICTS_SUCCESS,
  FETCH_LOCATION_ASSIGNMENT_COUNT,
  FETCH_LOCATION_ASSIGNMENT_COUNT_SUCCESS,
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_FAILED,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_OFFICE_LIST,
  FETCH_OFFICE_LIST_SUCCESS,
  FETCH_REGIONS_SUCCESS,
  REGION_DISTRICT_BULK_UPDATE,
  REGION_DISTRICT_BULK_UPDATE_FAILED,
  REGION_DISTRICT_BULK_UPDATE_STAFF,
  REGION_DISTRICT_BULK_UPDATE_SUCCESS,
  SET_SEARCH_LOCATIONS,
  SET_SEARCH_REGION_ID,
  SET_SELECTED_STAFF,
  TOGGLE_SELECTED_LOCATIONS,
  UNASSIGN_STAFF,
  FETCH_LOCATIONS_FOR_SEARCH,
  CLEAR_LOCATIONS_FOR_SEARCH,
  FETCH_LOCATIONS_FOR_SEARCH_SUCCESS,
  UPDATE_CONTACT_SPECIALTY,
  CLOSE_DRAWER,
} from './offices-actions';
import { LocationDto } from '../../models/location-dto';
import { DEFAULT_GRID_SIZE } from '@med-fe/util';
import { Paginated } from '../../models/pagination-dto';

export const LOCATIONS_STATE_KEY = 'LOCATIONS_REDUCER';

export interface ILocationsState {
  loading: boolean;
  locations: Paginated<LocationDto>;
  regions: any[];
  districts: any[];
  search: ISearchLocations;
  searchRegionId: number | null;
  contacts: any[];
  assignmentCount: any;
  error: any;
  selectedStaff: any;
  selectedLocations: number[];
  officeList: [];
  locationsForSearch: any[];
  loadingLocationNumbers: boolean;
  closeDrawer: boolean;
}

const defaultLocations = {
  total: 0,
  pages: 1,
  contents: [],
};

export interface ISearchLocations {
  dateFrom: null | string;
  dateTo: null | string;
  dateType: null | string;
  statuses: string[];
  districtIds: number[];
  page: number;
  regionIds: number[];
  search: string;
  size: number;
  locationIds: [];
  effectiveDate: any;
  branding: null | string;
  medicaidLocation: null | boolean;
  davisLocation: null | boolean;
  contactJob: null | string;
  contactName: null | string;
  address: null | string;
  lanSubnet: null | string;
  teleOptometry: null | boolean;
  specialtyEquipmentIds: number[];
  vendors: any[];
  closeDrawer: boolean;
}

export const defaultSearchLocations: ISearchLocations = {
  dateFrom: null,
  dateTo: null,
  dateType: null,
  statuses: ['ACTIVE'],
  districtIds: [],
  page: 0,
  regionIds: [],
  search: '',
  size: DEFAULT_GRID_SIZE,
  locationIds: [],
  effectiveDate: null,
  branding: null,
  medicaidLocation: null,
  davisLocation: null,
  contactJob: null,
  contactName: null,
  address: null,
  lanSubnet: null,
  teleOptometry: null,
  specialtyEquipmentIds: [],
  vendors: [],
  closeDrawer: false,
};

const defaultState: ILocationsState = {
  loading: false,
  locations: defaultLocations,
  regions: [],
  districts: [],
  search: defaultSearchLocations,
  searchRegionId: null,
  contacts: [],
  assignmentCount: {
    id: null,
    locations: 0,
  },
  error: null,
  selectedStaff: {},
  selectedLocations: [],
  officeList: [],
  locationsForSearch: defaultLocations.contents,
  loadingLocationNumbers: false,
  closeDrawer: false,
};

export const locationsReducer = (state = defaultState, { type, payload }: any) => {
  switch (type) {
    case FETCH_LOCATIONS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        locations: payload,
      };
    case FETCH_LOCATIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case CREATE_LOCATION:
    case CREATE_REGION:
    case CREATE_DISTRICT:
    case CREATE_BILLING:
    case UNASSIGN_STAFF:
      return state;
    case CREATE_LOCATION_SUCCESS:
    case CREATE_REGION_SUCCESS:
    case CREATE_DISTRICT_SUCCESS:
    case CREATE_BILLING_SUCCESS:
    case REGION_DISTRICT_BULK_UPDATE_SUCCESS:
      return {
        ...state,
      };
    case CREATE_LOCATION_FAILED:
    case CREATE_REGION_FAILED:
    case CREATE_DISTRICT_FAILED:
    case CREATE_BILLING_FAILED:
    case REGION_DISTRICT_BULK_UPDATE_FAILED:
      return {
        ...state,
        error: payload,
      };
    case DELETE_LOCATION:
      return state;
    case DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        locations: {
          ...state.locations,
          contents: state.locations.contents.filter((item: any) => item.id !== payload),
        },
      };
    case DELETE_LOCATION_FAILED:
      return {
        ...state,
        error: payload,
      };
    case FETCH_CONTACTS:
      return state;
    case FETCH_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: payload,
      };
    case FETCH_CONTACTS_FAIL:
      return {
        ...state,
        error: payload,
      };
    case FETCH_REGIONS_SUCCESS:
      return {
        ...state,
        regions: payload,
      };
    case FETCH_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: payload,
      };
    case FETCH_OFFICE_LIST:
      return {
        ...state,
      };
    case CLEAR_OFFICE_LIST:
      return {
        ...state,
        officeList: [],
      };
    case FETCH_OFFICE_LIST_SUCCESS:
      return {
        ...state,
        officeList: [...state.officeList, ...payload],
      };
    case FETCH_LOCATION_ASSIGNMENT_COUNT_SUCCESS:
      return {
        ...state,
        assignmentCount: payload,
      };
    case SET_SEARCH_REGION_ID:
      return {
        ...state,
        searchRegionId: payload,
      };
    case SET_SEARCH_LOCATIONS:
      return {
        ...state,
        search: { ...state.search, ...payload },
      };
    case SET_SELECTED_STAFF:
      return {
        ...state,
        selectedStaff: payload,
      };
    case TOGGLE_SELECTED_LOCATIONS:
      return {
        ...state,
        selectedLocations: payload,
      };
    case FETCH_LOCATIONS_FOR_SEARCH: {
      return {
        ...state,
        loadingLocationNumbers: true,
      };
    }
    case FETCH_LOCATIONS_FOR_SEARCH_SUCCESS: {
      return {
        ...state,
        loadingLocationNumbers: false,
        locationsForSearch: payload.page > 0 ? [...state.locationsForSearch, ...payload.locations] : payload.locations,
      };
    }
    case CLEAR_LOCATIONS_FOR_SEARCH: {
      return {
        ...state,
        locationsForSearch: [],
      };
    }
    case UPDATE_CONTACT_SPECIALTY: {
      return {
        ...state,
        locations: {
          ...state.locations,
          contents: state.locations.contents.map((location: any) => {
            const contact = location.staff?.find((item) => item.id === payload.id);

            if (contact) {
              contact['specialties'] = payload.specialties;
            }

            return location;
          }),
        },
      };
    }

    case FETCH_LOCATION_ASSIGNMENT_COUNT:
    case REGION_DISTRICT_BULK_UPDATE:
    case REGION_DISTRICT_BULK_UPDATE_STAFF:
    case CLOSE_DRAWER:
      return {
        ...state,
        closeDrawer: !state.closeDrawer, // Toggle the boolean value
      };
    default:
      return state;
  }
};
