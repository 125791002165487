import {
  SET_USER_ROLES,
  FETCH_EMAIL_GROUPS,
  FETCH_EMAIL_GROUPS_FAILED,
  FETCH_EMAIL_GROUPS_SUCCESS,
  FETCH_USER_EMAIL_GROUPS,
  FETCH_USER_EMAIL_GROUPS_FAILED,
  FETCH_USER_EMAIL_GROUPS_SUCCESS,
  UPDATE_USER_EMAIL_GROUPS,
  UPDATE_USER_EMAIL_GROUPS_FAILED,
  UPDATE_USER_EMAIL_GROUPS_SUCCESS,
} from './user-actions';
import {
  isAccessDeniedHelper,
  isReadAllAllowedHelper,
  isReadAllowedHelper,
  isReadMapAllowedHelper,
  isWriteAllAllowedHelper,
  isWriteAllowedHelper,
  isContactWriteAllowedHelper,
} from '../../common/helpers/permissions.helpers';

export const USER_STATE_KEY = 'USER_REDUCER';
export const getEmailGroups = (state: any) => state[USER_STATE_KEY].emailGroups;
export const getUserEmail = (state: any) => state[USER_STATE_KEY].userEmail;
export const getUserDataLoading = (state: any) => state[USER_STATE_KEY].loading;

export interface IUserEmail {
  id: number | null;
  email: string;
  emailGroups: string[];
  emailSubGroups: string[];
}

export interface IUserState {
  roles: string[];
  isReadAllAllowed: boolean;
  isWriteAllAllowed: boolean;
  readTabsPermissions: {
    locations: boolean;
    profile: boolean;
    finance: boolean;
    marketing: boolean;
    schedule: boolean;
    mvc: boolean;
    pro: boolean;
    it: boolean;
    veh: boolean;
    specialty: boolean;
    map: boolean;
    doctors: boolean;
    vendor: boolean;
  };
  writeTabsPermissions: {
    locations: boolean;
    profile: boolean;
    finance: boolean;
    marketing: boolean;
    schedule: boolean;
    mvc: boolean;
    pro: boolean;
    it: boolean;
    veh: boolean;
    specialty: boolean;
    map: boolean;
    doctors: boolean;
    vendor: boolean;
  };
  isAccessDenied: boolean;
  loading: boolean;
  emailGroups: string[];
  userEmail: IUserEmail;
}

const defaultState: IUserState = {
  roles: [],
  isReadAllAllowed: false,
  isWriteAllAllowed: false,
  readTabsPermissions: {
    locations: false,
    profile: false,
    finance: false,
    marketing: false,
    schedule: false,
    mvc: false,
    pro: false,
    it: false,
    veh: false,
    specialty: false,
    map: false,
    doctors: false,
    vendor: false,
  },
  writeTabsPermissions: {
    locations: false,
    profile: false,
    finance: false,
    marketing: false,
    schedule: false,
    mvc: false,
    pro: false,
    it: false,
    veh: false,
    specialty: false,
    map: false,
    doctors: false,
    vendor: false,
  },
  isAccessDenied: true,
  loading: false,
  emailGroups: [],
  userEmail: {
    id: null,
    email: '',
    emailGroups: [],
    emailSubGroups: [],
  },
};

export const userReducer = (state = defaultState, { type, payload }: any) => {
  switch (type) {
    case SET_USER_ROLES:
      return {
        ...state,
        roles: payload,
        isReadAllAllowed: isReadAllAllowedHelper(payload),
        isWriteAllAllowed: isWriteAllAllowedHelper(payload),
        readTabsPermissions: {
          locations: isReadAllowedHelper('locations', payload),
          profile: isReadAllowedHelper('profile', payload),
          finance: isReadAllowedHelper('finance', payload),
          marketing: isReadAllowedHelper('marketing', payload),
          schedule: isReadAllowedHelper('schedule', payload),
          mvc: isReadAllowedHelper('mvc', payload),
          pro: isReadAllowedHelper('pro', payload),
          it: isReadAllowedHelper('it', payload),
          veh: isReadAllowedHelper('veh', payload),
          telecom: isReadAllowedHelper('telecom', payload),
          specialty: isReadAllowedHelper('specialty', payload),
          vendor: isReadAllowedHelper('vendor', payload),
          map: isReadMapAllowedHelper(payload),
        },
        writeTabsPermissions: {
          locations: isWriteAllowedHelper('locations', payload),
          profile: isWriteAllowedHelper('profile', payload),
          finance: isWriteAllowedHelper('finance', payload),
          marketing: isWriteAllowedHelper('marketing', payload),
          schedule: isWriteAllowedHelper('schedule', payload),
          mvc: isWriteAllowedHelper('mvc', payload),
          pro: isWriteAllowedHelper('pro', payload),
          it: isWriteAllowedHelper('it', payload),
          veh: isWriteAllowedHelper('veh', payload),
          telecom: isWriteAllowedHelper('telecom', payload),
          specialty: isWriteAllowedHelper('specialty', payload),
          vendor: isWriteAllowedHelper('vendor', payload),
          map: isReadMapAllowedHelper(payload),
        },
        isReadDoctorsAllowed: isReadAllowedHelper('doctors', payload),
        isWriteDoctorsAllowed: isWriteAllowedHelper('doctors', payload),
        isAccessDenied: isAccessDeniedHelper(payload),
        isWriteContactAllowed: isContactWriteAllowedHelper('contact', payload),
      };
    case FETCH_EMAIL_GROUPS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EMAIL_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        emailGroups: payload,
      };
    case FETCH_EMAIL_GROUPS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case FETCH_USER_EMAIL_GROUPS:
      return {
        ...state,
        userEmail: { ...state.userEmail, email: payload },
        loading: true,
      };
    case FETCH_USER_EMAIL_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        userEmail: {
          ...state.userEmail,
          id: payload?.id,
          emailGroups: payload ? payload.emailGroups : [],
          emailSubGroups: payload ? payload.emailSubGroups : [],
        },
      };
    case FETCH_USER_EMAIL_GROUPS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_USER_EMAIL_GROUPS:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_EMAIL_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        userEmail: payload,
      };
    case UPDATE_USER_EMAIL_GROUPS_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getIsAccessDenied = (state: any) => state[USER_STATE_KEY].isAccessDenied;
export const getIsReadAllAllowed = (state: any) => state[USER_STATE_KEY].isReadAllAllowed;
export const getIsWriteAllAllowed = (state: any) => state[USER_STATE_KEY].isWriteAllAllowed;
export const getReadTabsPermissions = (state: any) => state[USER_STATE_KEY].readTabsPermissions;
export const getWriteTabsPermissions = (state: any) => state[USER_STATE_KEY].writeTabsPermissions;
export const getIsReadDoctorsAllowed = (state: any) => state[USER_STATE_KEY].isReadDoctorsAllowed;
export const getIsWriteDoctorsAllowed = (state: any) => state[USER_STATE_KEY].isWriteDoctorsAllowed;
export const getIsWriteContactAllowed = (state: any) => state[USER_STATE_KEY].isWriteContactAllowed;
