import { CheckBoxCell } from '@med-fe/ui';
// eslint-disable-next-line react-hooks/rules-of-hooks
export const office_vendor_columns = [
  {
    title: 'Location number',
    dataIndex: 'locationNumber',
    key: 'action',
    width: 120,
    fixed: 'left',
  },
  {
    title: 'Location name',
    dataIndex: 'locationName',
    key: 'locationName',
    width: 200,
  },
];
