export const addressConfig: any = (isCreateMode = false) => ({
  street: {
    label: 'Street',
    isVisible: true,
    disabled: true,
  },
  street2: {
    label: 'Street 2',
    isVisible: true,
    disabled: true,
  },
  city: {
    label: 'City',
    isVisible: true,
    disabled: true,
  },
  state: {
    label: 'State',
    disabled: true,
    isVisible: true,
  },
  zip: {
    label: 'ZIP',
    disabled: true,
    isVisible: true,
  },
  phone: {
    label: 'Phone',
    disabled: true,
    isVisible: true,
  },
  fax: {
    label: 'Fax',
    isVisible: true,
    disabled: true,
  },
  directDial: {
    label: 'Direct Dial',
    disabled: true,
    isVisible: true,
  },
  timezone: {
    label: 'Timezone',
    disabled: true,
    isVisible: true,
  },
});
