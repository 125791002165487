import { YEAR_VALIDATION_RULE, AMOUNT_VALIDATION_RULE } from '@med-fe/util';

export const billingAccountInfoConfig: any = () => ({
  merchantBankAccountNumber: {
    label: 'Merchant Bank Account Number',
    isVisible: true,
  },
  drOfRecord: {
    label: 'Dr Of Record',
    isVisible: true,
  },
  oracleLegalEntityNum: {
    label: 'Oracle Legal Entity Num',
    isVisible: true,
  },
  oracleOfficeNum: {
    label: 'Oracle Office Num',
    isVisible: true,
  },
  depositoryBankAccount: {
    label: 'Depository Bank Account',
    isVisible: true,
  },
  worldpayMerchantId: {
    label: 'Worldpay Merchant Id',
    isVisible: true,
  },
  careCreditMerchantId: {
    label: 'Care Credit Merchant Id',
    isVisible: true,
  },
  worldpaySerialNumber: {
    label: 'Worldpay Serial Number',
    isVisible: true,
  },
  locationCategory: {
    label: 'Location Category',
    isVisible: true,
    type: 'select',
  },
  newOfficeYear: {
    //locationCategory=De-Novo
    label: 'New Office Year',
    isVisible: false,
    type: 'text',
    rules: [{ required: true, message: 'please enter New office year' }, YEAR_VALIDATION_RULE],
  },
  jointVentureYear: {
    //locationCategory=JointVenture
    label: 'Joint Venture Year',
    isVisible: false,
    type: 'text',
    rules: [{ required: true, message: 'please enter Joint Venture year' }, YEAR_VALIDATION_RULE],
  },
  acquisitionYear: {
    //locationCategory=Acquisition
    label: 'Acquisition Year',
    isVisible: false,
    type: 'text',
    rules: [{ required: true, message: 'please enter Acquisition Year' }, YEAR_VALIDATION_RULE],
  },
  preAcquisitionAnnualRevenue: {
    //locationCategory=JointVenture, Acquisition
    label: 'Pre-Acquisition Annual Revenue (in $)',
    isVisible: false,
    type: 'text',
    rules: [{ required: true, message: 'please enter Pre-Acquisition Annual Revenue' }, AMOUNT_VALIDATION_RULE],
  },
  vintageName: {
    //locationCategory=Acquisition
    label: 'Vintage Name',
    isVisible: false,
    type: 'text',
    rules: [{ required: true, message: 'please enter Vintage Name' }],
  },
  sameOfficeSales: {
    label: 'Same Office Sales',
    type: 'checkbox',
    isVisible: true,
  },
  sameOfficeChangeDate: {
    label: 'Same Office Change Date',
    type: 'date',
    isVisible: true,
  },
});
