import * as Eff from 'redux-saga/effects';
import { CREATE_ADDRESS, DELETE_ADDRESS, FETCH_ADDRESS } from './address/address-actions';
import { createAddressStartWorker, deleteAddressStartWorker, fetchAddressStartWorker } from './address/address-sagas';
import {
  CREATE_BILLING,
  CREATE_DISTRICT,
  CREATE_LOCATION,
  CREATE_REGION,
  DELETE_LOCATION,
  FETCH_CONTACTS,
  FETCH_DISTRICTS,
  FETCH_LOCATION_ASSIGNMENT_COUNT,
  FETCH_LOCATIONS,
  FETCH_REGIONS,
  REGION_DISTRICT_BULK_UPDATE,
  UPDATE_CONTACT,
  SEARCH_DISTRICTS,
  REGION_DISTRICT_BULK_UPDATE_STAFF,
  UNASSIGN_STAFF,
  FETCH_OFFICE_LIST,
  FETCH_LOCATIONS_FOR_SEARCH,
  DELETE_VENDOR,
  SAVE_VENDOR_ACCOUNTS,
} from '../components/offices/offices-actions';
import {
  createBillingStartWorker,
  createDistrictStartWorker,
  createLocationStartWorker,
  createRegionStartWorker,
  deleteLocationStartWorker,
  fetchContactsStartWorker,
  fetchDistrictsStartWorker,
  fetchLocationAssignmentCountStartWorker,
  fetchLocationsStartWorker,
  fetchRegionsStartWorker,
  regionDistrictBulkUpdateStartWorker,
  updateContactStartWorker,
  fetchLocationsForSearchStartWorker,
  searchDistrictsByAreaStartWorker,
  bulkUpdateStaffStartWorker,
  unassignStuffStartWorker,
  fetchOfficeListStartWorker,
  deleteVendorStartWorker,
  saveVendorAccountStartWorker,
} from '../components/offices/offices-sagas';
import { EXPORT_FILE, IMPORT_FILE, UPDATE_VALIDATED_IMPORT } from '../components/header/header-actions';
import { FETCH_EMAIL_GROUPS, FETCH_USER_EMAIL_GROUPS, UPDATE_USER_EMAIL_GROUPS } from './user/user-actions';
import {
  exportFileStartWorker,
  importBulkUpdateFileStartWorker,
  updateValidatedImportStartWorker,
} from '../components/header/header-sagas';
import {
  fetchEmailGroupsStartWorker,
  fetchUserEmailGroupsStartWorker,
  updateUserEmailGroupsStartWorker,
} from './user/user-sagas';
import { FETCH_AUDIT } from '../components/audit/audit-actions';
import { fetchAuditStartWorker } from '../components/audit/audit-sagas';
import { FETCH_LOCATIONS_COORDINATES } from '../components/map/map-actions';
import { fetchLocationsCoordinatesStartWorker } from '../components/map/map-sagas';
import {
  CREATE_SCHEDULE,
  FETCH_EFFECTIVE_DATES,
  FETCH_SCHEDULES_MODEL_NAMES,
  FETCH_SCHEDULES,
  FETCH_SCHEDULE_MODELS,
} from './schedule/schedule-actions';
import {
  createSchedulesStartWorker,
  fetchEffectiveDatesStartWorker,
  fetchScheduleModelNamesStartWorker,
  fetchScheduleModelsStartWorker,
  fetchSchedulesStartWorker,
} from './schedule/schedule-sagas';
import {
  fetchDoctorsStartWorker,
  fetchDoctorByIdStartWorker,
  updateDoctorStartWorker,
  uploadDoctorPhotoStartWorker,
} from '../components/doctors/doctors-sagas';
import {
  FETCH_DOCTORS,
  FETCH_DOCTOR_BY_ID,
  UPDATE_DOCTOR,
  UPLOAD_DOCTOR_PHOTO,
} from '../components/doctors/doctors-actions';
import { FETCH_ALL_SPECIALTIES, FETCH_EQUIPMENT } from './common/common-actions';
import { fetchAllSpecialtiesStartWorker, fetchEquipmentStartWorker } from './common/common-sagas';
import {
  FETCH_ACTIVITY_STATISTICS,
  FETCH_DOCTORS_STATISTICS,
  FETCH_OFFICES_STATISTICS,
} from '../components/statistics/statistics-actions';
import {
  fetchActivityStatisticsStartWorker,
  fetchDoctorsStatisticsStartWorker,
  fetchOfficesStatisticsStartWorker,
} from '../components/statistics/statistics-sagas';
import { CREATE_VENDOR, FETCH_VENDORS, SEARCH_VENDORS } from '../components/vendors/vendors-actions';
import {
  createVendorStartWorker,
  fetchVendorStartWorker,
  searchVendorStartWorker,
} from '../components/vendors/vendors-sagas';

const takeEvery: any = Eff.takeEvery;

export default function* rootSaga() {
  yield takeEvery(FETCH_ADDRESS, fetchAddressStartWorker);
  yield takeEvery(CREATE_ADDRESS, createAddressStartWorker);
  yield takeEvery(DELETE_ADDRESS, deleteAddressStartWorker);

  yield takeEvery(FETCH_LOCATIONS, fetchLocationsStartWorker);
  yield takeEvery(CREATE_LOCATION, createLocationStartWorker);
  yield takeEvery(DELETE_LOCATION, deleteLocationStartWorker);

  yield takeEvery(FETCH_AUDIT, fetchAuditStartWorker);

  yield takeEvery(EXPORT_FILE, exportFileStartWorker);
  yield takeEvery(IMPORT_FILE, importBulkUpdateFileStartWorker);
  yield takeEvery(UPDATE_VALIDATED_IMPORT, updateValidatedImportStartWorker);
  yield takeEvery(FETCH_USER_EMAIL_GROUPS, fetchUserEmailGroupsStartWorker);
  yield takeEvery(FETCH_EMAIL_GROUPS, fetchEmailGroupsStartWorker);
  yield takeEvery(UPDATE_USER_EMAIL_GROUPS, updateUserEmailGroupsStartWorker);

  yield takeEvery(FETCH_REGIONS, fetchRegionsStartWorker);
  yield takeEvery(FETCH_DISTRICTS, fetchDistrictsStartWorker);
  yield takeEvery(SEARCH_DISTRICTS, searchDistrictsByAreaStartWorker);

  yield takeEvery(FETCH_VENDORS, fetchVendorStartWorker);
  yield takeEvery(SEARCH_VENDORS, searchVendorStartWorker);
  yield takeEvery(CREATE_VENDOR, createVendorStartWorker);
  yield takeEvery(DELETE_VENDOR, deleteVendorStartWorker);

  yield takeEvery(FETCH_OFFICE_LIST, fetchOfficeListStartWorker);

  yield takeEvery(SAVE_VENDOR_ACCOUNTS, saveVendorAccountStartWorker);

  yield takeEvery(FETCH_LOCATION_ASSIGNMENT_COUNT, fetchLocationAssignmentCountStartWorker);
  yield takeEvery(UPDATE_CONTACT, updateContactStartWorker);
  yield takeEvery(FETCH_CONTACTS, fetchContactsStartWorker);

  yield takeEvery(CREATE_REGION, createRegionStartWorker);
  yield takeEvery(CREATE_DISTRICT, createDistrictStartWorker);
  yield takeEvery(REGION_DISTRICT_BULK_UPDATE, regionDistrictBulkUpdateStartWorker);

  yield takeEvery(CREATE_BILLING, createBillingStartWorker);

  yield takeEvery(FETCH_LOCATIONS_COORDINATES, fetchLocationsCoordinatesStartWorker);

  yield takeEvery(FETCH_LOCATIONS_FOR_SEARCH, fetchLocationsForSearchStartWorker);

  yield takeEvery(FETCH_SCHEDULES, fetchSchedulesStartWorker);
  yield takeEvery(CREATE_SCHEDULE, createSchedulesStartWorker);
  yield takeEvery(FETCH_EFFECTIVE_DATES, fetchEffectiveDatesStartWorker);
  yield takeEvery(REGION_DISTRICT_BULK_UPDATE_STAFF, bulkUpdateStaffStartWorker);
  yield takeEvery(UNASSIGN_STAFF, unassignStuffStartWorker);

  yield takeEvery(FETCH_SCHEDULES_MODEL_NAMES, fetchScheduleModelNamesStartWorker);
  yield takeEvery(FETCH_SCHEDULE_MODELS, fetchScheduleModelsStartWorker);

  yield takeEvery(FETCH_DOCTORS, fetchDoctorsStartWorker);
  yield takeEvery(FETCH_DOCTOR_BY_ID, fetchDoctorByIdStartWorker);
  yield takeEvery(UPDATE_DOCTOR, updateDoctorStartWorker);
  yield takeEvery(UPLOAD_DOCTOR_PHOTO, uploadDoctorPhotoStartWorker);
  yield takeEvery(FETCH_ALL_SPECIALTIES, fetchAllSpecialtiesStartWorker);
  yield takeEvery(FETCH_EQUIPMENT, fetchEquipmentStartWorker);

  yield takeEvery(FETCH_OFFICES_STATISTICS, fetchOfficesStatisticsStartWorker);
  yield takeEvery(FETCH_DOCTORS_STATISTICS, fetchDoctorsStatisticsStartWorker);
  yield takeEvery(FETCH_ACTIVITY_STATISTICS, fetchActivityStatisticsStartWorker);
}
