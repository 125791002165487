import React, { useEffect, useState } from 'react';
import { capitalize, get } from 'lodash';
import { Button, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createLocation, fetchDistricts, fetchRegions } from '../../offices/offices-actions';
import { getDistricts, getLocationDetails, getRegions, getSearchRegionId } from '../../offices/offices-selectors';
import { locationConfig } from '../configs/location-address-config';
import { formItemMap } from '@med-fe/ui';
import { StatusesEnum } from '../../../models/statuses-enum';
import { getWriteTabsPermissions } from '../../../store/user/user-reducer';
import { getIsWriteAllAllowed } from '../../../store/user/user-reducer';
import { LocationCategory } from 'apps/mod/src/models/locationCategory-enum';
import { PosEnum } from 'apps/mod/src/models/pos-enum';
import moment from 'moment';

interface ILocationDetailsProps {
  locId?: string;
  isCreateMode?: boolean;
  onClose?: any;
}

function LocationDetails({ locId, isCreateMode = false, onClose }: ILocationDetailsProps) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [regionId, setRegionId]: any = useState(null);
  const [districtId, setDistrictId]: any = useState(null);
  const [isDisabled, setIsDisabled]: any = useState(true);
  const [statusValue, setStatusValue]: any = useState(false);
  const name = 'location-details';
  const classNameForm = `${name}-form`;

  const regions = useSelector(getRegions);
  const districts = useSelector(getDistricts);
  const searchRegionId = useSelector(getSearchRegionId);
  const data: any = useSelector((state) => {
    if (locId) {
      // Edit mode
      return getLocationDetails(state, locId);
    } else {
      // Create mode
      return null;
    }
  });
  const tabWritePermissions = useSelector(getWriteTabsPermissions).locations;
  const isWriteAllAllowed = useSelector(getIsWriteAllAllowed);
  const isEditAllowed = tabWritePermissions || isWriteAllAllowed;
  const disCheck = isEditAllowed && !isDisabled ? false : true;
  const [locationCategory, setLocationCategory] = useState(data?.locationCategory ? data?.locationCategory : null);
  const [pos, setpos] = useState(null);

  useEffect(() => {
    if (isCreateMode) {
      form.setFieldsValue({ status: 'HOLD' });
      setStatusValue('HOLD');
      dispatch(fetchRegions());
    } else {
      form.setFieldsValue({
        ...data,
        ...(!!data.openingDate && { openingDate: moment(data.openingDate) }),
        status: get(data, 'status', null),
      });
      setRegionId(get(data, 'region', null));
      setStatusValue(get(data, 'status', null));
    }
    return () => {
      dispatch(fetchDistricts(searchRegionId));
      dispatch(fetchRegions());
      form.resetFields();
    };
  }, [locId]);

  useEffect(() => {
    if (regionId) {
      dispatch(fetchDistricts(regionId));
    }
  }, [regionId]);

  const onSubmit = (formData) => {
    const {
      region,
      district,
      status,
      locationName,
      locationNumber,
      timezone,
      openingDate,
      newOfficeYear,
      jointVentureYear,
      acquisitionYear,
      preAcquisitionAnnualRevenue,
      vintageName,
      pos,
      encompassLaunchDate,
      dryEyeTreatment = false,
      locationCategory,
      ...address
    } = formData;
    const isAddressTouched = form.isFieldsTouched(Object.keys(address));
    const fullAddress = { ...address, lng: data?.lng, lat: data?.lat, type: data?.type };
    let payload = {
      ...(form.isFieldTouched('region') && (region ? { region: { id: region } } : { region: null })),
      ...(form.isFieldTouched('district') &&
        (district ? { district: { id: district } } : region && { district: null })),
      ...(timezone && form.isFieldTouched('timezone') && { timezone }),
      ...(openingDate && form.isFieldTouched('openingDate') && { openingDate }),
      ...(newOfficeYear && form.isFieldTouched('newOfficeYear') && { newOfficeYear }),
      ...(jointVentureYear && form.isFieldTouched('jointVentureYear') && { jointVentureYear }),
      ...(acquisitionYear && form.isFieldTouched('acquisitionYear') && { acquisitionYear }),
      ...(preAcquisitionAnnualRevenue &&
        form.isFieldTouched('preAcquisitionAnnualRevenue') && { preAcquisitionAnnualRevenue }),
      ...(vintageName && form.isFieldTouched('vintageName') && { vintageName }),
      ...(encompassLaunchDate && form.isFieldTouched('encompassLaunchDate') && { encompassLaunchDate }),
      ...(pos && form.isFieldTouched('pos') && { pos }),
      //...(status && form.isFieldTouched('status') && { status }),
      ...(dryEyeTreatment && form.isFieldTouched('dryEyeTreatment') && { dryEyeTreatment }),
      ...(locationCategory && form.isFieldTouched('locationCategory') && { locationCategory }),
      ...(isAddressTouched && {
        address: {
          id: get(data, 'id', null),
          ...fullAddress,
        },
      }),
    };

    if (isCreateMode) {
      payload = {
        ...payload,
        locationName,
        locationNumber,
        status: status || 'HOLD',
        vintageName:
          locationCategory == 'DENOVO'
            ? newOfficeYear + ' De Novo'
            : locationCategory === 'JOINT_VENTURE'
            ? jointVentureYear + ' Joint Venture'
            : locationCategory === 'ACQUISITION'
            ? payload.vintageName
            : null,
      };
    } else {
      payload = {
        id: locId,
        ...payload,
        ...(status && form.isFieldTouched('status') && { status }),
        locationName,
      };
    }
    dispatch(createLocation(payload));
    setIsDisabled(true);
    if (isCreateMode) {
      onClose();
    }
  };

  function resetDistrict() {
    form.setFieldsValue({ district: null });
    form.validateFields(['district']);
  }

  const onRegionSelect = (value) => {
    setRegionId(value);
    resetDistrict();
  };

  const onDistrictSelect = (value) => {
    setDistrictId(value);
  };

  const onStatusSelect = (value) => {
    setStatusValue(value);
  };

  const onLocationCategorySelect = (value) => {
    setLocationCategory(value);
  };

  const onPOSSelect = (value) => {
    setpos(value);
  };

  const onYearUpdate = (value) => {
    form.setFieldsValue({ vintageName: value.currentTarget.value + ' Acquisition' });
  };

  const content = (key: any, value: any, data: any) => {
    const checkboxProp: any = key === 'dryEyeTreatment' && { valuePropName: 'checked' };
    const formItemParams = {
      ...value,
      data: data(),
      ...(key === 'region' && { onSelect: onRegionSelect }),
      ...(key === 'district' && { onSelect: onDistrictSelect }),
      ...(key === 'status' && { onSelect: onStatusSelect, data: Object.entries(StatusesEnum) }),
      ...(key === 'locationCategory' && {
        onSelect: onLocationCategorySelect,
        data: Object.entries(LocationCategory),
      }),
      ...(key === 'acquisitionYear' && { onBlur: onYearUpdate }),
      ...(key === 'pos' && {
        onSelect: onPOSSelect,
        data: Object.entries(PosEnum),
      }),
    };
    if (value.isVisible && ((isCreateMode && value.add) || (!isCreateMode && value.edit)))
      return (
        <Form.Item
          key={key}
          label={value.label}
          name={key}
          rules={
            key === 'district' || key === 'region'
              ? [{ required: !!regionId || !!districtId, message: `Please select ${capitalize(key)}` }]
              : value.rules
          }
          validateTrigger={['onBlur', 'onSubmit']}
          className={`${classNameForm}-${key}`}
          {...checkboxProp}
        >
          {formItemMap(formItemParams, !isEditAllowed)}
        </Form.Item>
      );
    else if (
      (key === 'openingDate' && statusValue === 'HOLD') ||
      (key === 'newOfficeYear' && locationCategory === 'DENOVO') ||
      (key === 'jointVentureYear' && locationCategory === 'JOINT_VENTURE') ||
      (key === 'acquisitionYear' && locationCategory === 'ACQUISITION') ||
      (key === 'preAcquisitionAnnualRevenue' &&
        (locationCategory === 'ACQUISITION' || locationCategory === 'JOINT_VENTURE')) ||
      (key === 'vintageName' && locationCategory === 'ACQUISITION') ||
      (key === 'encompassLaunchDate' && pos === 'ENCOMPASS')
    ) {
      return (
        <Form.Item
          key={key}
          label={value.label}
          name={key}
          rules={value.rules}
          validateTrigger={['onBlur', 'onSubmit']}
          className={`${classNameForm}-${key}`}
        >
          {formItemMap(formItemParams, !isEditAllowed)}
        </Form.Item>
      );
    } else return null;
  };

  return (
    <Form
      form={form}
      name={name}
      layout='vertical'
      onFinish={onSubmit}
      autoComplete='off'
      className={classNameForm}
      onValuesChange={() => setIsDisabled(false)}
    >
      {Object.entries(locationConfig(isCreateMode)).map(([key, value]: [string, any]) => {
        const data = () => {
          switch (key) {
            case 'region':
              return regions;
            case 'district':
              return districts;
            case 'status':
              return Object.entries(StatusesEnum);
          }
        };
        return content(key, value, data);
      })}

      <Form.Item>
        <Button type='primary' htmlType='submit' disabled={disCheck} className={'btn-form-action'}>
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
}

export default LocationDetails;
