import { Form, Input, InputRef, Modal } from 'antd';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import type { FormInstance } from 'antd/es/form';
// import './EditableCell.module.scss';
const EditableContext = createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: any;
}

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false} initialValues={index}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: any;
  moduleKey: string;
  editable: boolean;
  dataIndex: any;
  inputType: any;
  record?: any;
  index?: number;
  children?: React.ReactNode;
  onSaveChanges: any;
}

const EditableCell: React.FC<EditableCellProps> = ({
  onSaveChanges,
  editable,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const form = useContext(EditableContext)!;
  const inputRef = useRef<InputRef>(null);

  // Handle the input nodes based on the `inputType`
  const inputNode =
    inputType === 'accountNumber' ? (
      <Input
        ref={inputRef}
        onPressEnter={(e: any) => onSaveChanges({ record, targetValue: e.target.value, name: e.target.name })}
        onBlur={(e: any) => onSaveChanges({ record, targetValue: e.target.value, name: e.target.name })}
        placeholder='Enter acc no.'
        name={title}
        defaultValue={!record?.accountNumber ? record?.accountNumber : record?.accountNumber}
      />
    ) : (
      ''
    );

  // Toggle between editing and viewing mode
  const toggleEdit = () => {
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  return (
    <>
      <td {...restProps}>
        {inputType === 'accountNumber' ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please enter acc no.`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : inputType === 'accountNumber' ? (
          <div className='editable-cell-value-wrap' style={{ paddingRight: 24 }} onClick={toggleEdit}>
            {children}
          </div>
        ) : (
          children
        )}
      </td>
    </>
  );
};

export default EditableCell;
