import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { fetchVendors, searchVendor, setSearchVendor, setSortCriterias } from './vendors-actions';
import { getLoading, getSearch, getSorting, getTotal } from './vendors-selectors';
import { getReadTabsPermissions, getWriteTabsPermissions } from '../../store/user/user-reducer';

import BaseGrid from '../grid/BaseGrid';
import SearchVendors from '../header/search/SearchVendors';
import { omit } from 'lodash';

function Vendors() {
  const dispatch = useDispatch();
  const [isAllSelected, setIsAllSelected] = useState(false);

  const total = useSelector(getTotal);
  const search = useSelector(getSearch);
  const loading = useSelector(getLoading);
  const sorting = useSelector(getSorting);
  useEffect(() => {
    dispatch(searchVendor({ sorting }));
    dispatch(fetchVendors());
  }, []);
  useEffect(() => {
    dispatch(searchVendor({ ...search, sorting }));
  }, [search]);

  const onPageChange = (pageData) => {
    dispatch(setSearchVendor({ ...search, ...omit(pageData, sorting) }));
    if (pageData.sorting) {
      dispatch(setSortCriterias(pageData.sorting));
    }
  };

  return (
    <>
      <SearchVendors />
      <BaseGrid
        id='vendorMaintenance'
        total={total}
        isAllSelected={isAllSelected}
        toggleIsAllSelected={(val) => setIsAllSelected(val)}
        search={search}
        setSearch={onPageChange}
        loading={loading}
      />
    </>
  );
}

export default Vendors;
