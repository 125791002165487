import React, { useEffect, useState } from 'react';
import { Button, Collapse } from 'antd';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getCloseDrawer, getLocationById } from '../offices/offices-selectors';
import styles from './CollapsiblePanel.module.scss';
import { officesTabsConfig } from '../offices/offices-tabs-config';
import ExpandIcon from '../common/ExpandIcon';
import { closeTabDrawer } from '../offices/offices-actions';

const { Panel } = Collapse;

function CollapsiblePanel({ id, locId, hideDetails }) {
  const dispatch = useDispatch();
  const location = useSelector((state) => getLocationById(state, locId));
  const [details, setDetails] = useState({});
  const closeDrawer = useSelector(getCloseDrawer);

  useEffect(() => {
    return () => setDetails({});
  }, []);

  useEffect(() => {
    if (location) {
      const { details } = officesTabsConfig(locId)[id];
      setDetails(details);
    } else {
      hideDetails();
      setDetails({});
    }
  }, [locId, location]);

  useEffect(() => {
    if (closeDrawer && id === 'vendor') {
      hideDetails();
      setDetails({});
      dispatch(closeTabDrawer());
    }
  }, [closeDrawer]);

  useEffect(() => {
    setDetails({});
  }, [id]);

  return isEmpty(details) ? null : (
    <div className={styles['details-panel']}>
      <div className={styles['details-panel-header']}>
        {location && <span>{location.locationNumber}</span>}
        <Button
          type={'ghost'}
          className={styles['details-panel-btn']}
          onClick={() => {
            hideDetails();
            setDetails({});
          }}
        >
          CLOSE ALL &#10006;
        </Button>
      </div>

      <Collapse
        destroyInactivePanel
        defaultActiveKey={Object.keys(details)}
        bordered={false}
        expandIcon={({ isActive }) => <ExpandIcon isActive={isActive} />}
        className='med-custom-collapse'
        ghost={true}
      >
        {!isEmpty(details) &&
          Object.entries(details).map(([key, value]: [string, any]) => (
            <Panel header={key.split('_').join(' ').toUpperCase()} key={key} className='med-custom-panel'>
              {value}
            </Panel>
          ))}
      </Collapse>
    </div>
  );
}

export default CollapsiblePanel;
