import { CheckBoxCell } from '@med-fe/ui';

export const vendor_columns = [
  {
    title: 'Vendor name',
    dataIndex: 'name',
    key: 'vendorName',
    sorter: (a: { name: string }, b: { name: any }) => a.name?.localeCompare(b.name),
    sortDirections: ['ascend', 'descend'],
    width: 150,
  },
  {
    title: 'isDefault',
    dataIndex: 'isDefault',
    key: 'isDefault',
    width: 100,
    colType: 'checkbox',
    sorter: (a: { isDefault: boolean }, b: { isDefault: boolean }) =>
      a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1,
    sortDirections: ['ascend', 'descend'],
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
  {
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
    width: 100,
    colType: 'checkbox',
    sorter: (a: { active: boolean }, b: { active: boolean }) => (a.active === b.active ? 0 : a.active ? -1 : 1),
    sortDirections: ['ascend', 'descend'],
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
];
