import { Button, Form, Select, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVehForLocation } from '../../offices/offices-selectors';
import { capitalize } from 'lodash';
import { createLocation, unassignStuff, updateContact } from '../../offices/offices-actions';
import { vehConfig } from '../configs/veh-config';
import { getWriteTabsPermissions } from '../../../store/user/user-reducer';
import { formItemMap } from '@med-fe/ui';
import moment from 'moment';
import { getContacts } from '../../offices/offices-selectors';
import { fetchContacts } from '../../offices/offices-actions';

function VehDetails({ locId }) {
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();
  const data = useSelector((state) => getVehForLocation(state, locId));
  const isEditAllowed = useSelector(getWriteTabsPermissions).veh;
  const doctors = useSelector(getContacts);
  const [isCombination, setIsCombination] = useState<boolean>(false);
  const [vehDoctor, setVehDoctor] = useState<any>({});
  const [doctorSearch, setDoctorSearch] = useState('');

  useEffect(() => {
    setVehDoctor(data.doctor);
    setIsCombination(data.doctorNetwork === 'Combination');
    if (data.doctorNetwork === 'Combination') {
      dispatch(fetchContacts({ job: 'OD', name: '' }));
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      ...(!!data.callCenter && { callCenter: moment(data.callCenter) }),
      ...(!!data.vehLastDay && { vehLastDay: moment(data.vehLastDay) }),
      ...(!!data.vehGoLive && { vehGoLive: moment(data.vehGoLive) }),
      ...(!!data.pxpLaunch && { pxpLaunch: moment(data.pxpLaunch) }),
    });
  }, [locId]);

  useEffect(() => {
    if (vehDoctor && data.doctor) {
      setIsDisabled(vehDoctor?.id === data.doctor?.id);
    }
  }, [vehDoctor, data.doctor]);

  const onSubmit = (formData: any) => {
    console.log(formData);

    const { teleOptometry, ...formDataValues } = formData;
    const payload = {
      locationVeh: [
        {
          ...formDataValues,
          ...(!!formDataValues.callCenter && { callCenter: formDataValues.callCenter.format('YYYY-MM-DD') }),
          ...(!!formDataValues.vehLastDay && { vehLastDay: formDataValues.vehLastDay.format('YYYY-MM-DD') }),
          ...(!!formDataValues.vehGoLive && { vehGoLive: formDataValues.vehGoLive.format('YYYY-MM-DD') }),
          ...(!!formDataValues.pxpLaunch && { pxpLaunch: formDataValues.pxpLaunch.format('YYYY-MM-DD') }),
          id: data.vehId,
        },
      ],
      id: locId,
      teleOptometry: teleOptometry,
    };
    dispatch(createLocation(payload));

    if (data.doctor && (vehDoctor.id !== data.doctor.id || (!isCombination && data.doctor.id))) {
      dispatch(unassignStuff({ locationId: locId, contactId: data.doctor.id }));
    }
    if (vehDoctor.id && ((data.doctor && vehDoctor?.id !== data.doctor.id) || (isCombination && vehDoctor.id))) {
      dispatch(
        updateContact({
          assign: { contactId: vehDoctor.id, locationId: locId, job: 'TELE', privateLocation: false },
        })
      );
    }
  };

  const onDoctorNetworkChange = (value) => {
    if (value === 'Open Network') {
      setIsCombination(false);
      setVehDoctor({});
      setDoctorSearch('');
    } else if (value === 'Combination') {
      setIsCombination(true);
      dispatch(fetchContacts({ job: 'OD', name: '' }));
    }
  };

  return (
    <Form
      form={form}
      layout='vertical'
      className='pro-service-details-form'
      onFinish={(formData) => {
        onSubmit(formData);
        setIsDisabled(true);
      }}
      onValuesChange={() => setIsDisabled(false)}
    >
      {Object.entries(vehConfig).map(([key, value]: any) => {
        const params = {
          key,
          label: capitalize(value.label),
          name: key,
          rules: value.rules,
          data: value.data,
          ...(value.type === 'checkbox' && { valuePropName: 'checked' }),
        };

        return <Form.Item {...params}>{formItemMap(value, !isEditAllowed)}</Form.Item>;
      })}

      <Form.Item label='Doctor Network' key={'doctorNetwork'} name='doctorNetwork'>
        <Select
          allowClear
          disabled={!isEditAllowed}
          onClear={() => {
            setVehDoctor({});
            dispatch(fetchContacts({ job: 'OD', name: '' }));
            setIsCombination(false);
            setDoctorSearch('');
          }}
          onSelect={(value) => onDoctorNetworkChange(value)}
        >
          <Select.Option key={'Open Network'}>Open Network</Select.Option>
          <Select.Option key={'Combination'}>Combination</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        tooltip={!isCombination && 'only for doctor network is combination'}
        label='Doctor (search by Name or create new)'
        className='form-item-with-custom-tooltip'
      >
        <Select
          allowClear
          showSearch
          disabled={!isCombination || !isEditAllowed}
          value={doctorSearch}
          onChange={setDoctorSearch}
          onClear={() => setVehDoctor({})}
          onSearch={(value) => {
            dispatch(fetchContacts({ job: 'OD', name: value }));
          }}
          onSelect={(event, value) => {
            setVehDoctor(value);
          }}
          options={doctors.map(({ name, ...info }) => ({ ...info, name, value: name }))}
          defaultValue={vehDoctor?.name}
        />
      </Form.Item>
      {vehDoctor && isCombination && (
        <div>
          <Form.Item label={'Doctor Name'}>
            <Input disabled value={vehDoctor?.name} />
          </Form.Item>
          <Form.Item label={'Doctor Phone'}>
            <Input disabled value={vehDoctor?.phone} />
          </Form.Item>
          <Form.Item label={'Doctor Email'}>
            <Input disabled value={vehDoctor?.email} />
          </Form.Item>
          <Form.Item label={'Doctor Start Date'}>
            <Input disabled value={vehDoctor?.startDate} />
          </Form.Item>
        </div>
      )}
      <Form.Item>
        <Button type='primary' htmlType='submit' className={'btn-form-action'} disabled={!isEditAllowed || isDisabled}>
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
}

export default VehDetails;
