export const SEARCH_VENDORS = `SEARCH_VENDORS`;
export const SEARCH_VENDORS_SUCCESS = `SEARCH_VENDORS_SUCCESS`;
export const SEARCH_VENDORS_FAILED = `SEARCH_VENDORS_FAILED`;

export const SET_SEARCH_VENDORS = 'SET_SEARCH_VENDORS';
export const SET_SORT_CRITERIAS = 'SET_SORT_CRITERIAS';

export const FETCH_VENDORS = `FETCH_VENDORS`;
export const FETCH_VENDORS_SUCCESS = `FETCH_VENDORS_SUCCESS`;
export const FETCH_VENDORS_FAILED = `FETCH_VENDORS_FAILED`;

export const CREATE_VENDOR = `CREATE_VENDOR`;
export const CREATE_VENDOR_SUCCESS = `CREATE_VENDOR_SUCCESS`;
export const CREATE_VENDOR_FAILED = `CREATE_VENDOR_FAILED`;

export const fetchVendors = (payload?: any) => ({
  type: FETCH_VENDORS,
  ...(payload && { payload }),
});

export const fetchVendorsSuccess = (payload: any) => ({
  type: FETCH_VENDORS_SUCCESS,
  payload,
});

export const fetchVendorsFailed = (payload: any) => ({
  type: FETCH_VENDORS_FAILED,
  payload,
});

export const createVendor = (payload: any) => ({
  type: CREATE_VENDOR,
  payload,
});

export const createVendorSuccess = (payload: any) => ({
  type: CREATE_VENDOR_SUCCESS,
  payload,
});

export const createVendorFailed = (payload: any) => ({
  type: CREATE_VENDOR_FAILED,
  payload,
});

export const setSearchVendor = (payload) => ({
  type: SET_SEARCH_VENDORS,
  payload,
});

export const searchVendor = (payload) => ({
  type: SEARCH_VENDORS,
  payload,
});

export const setSortCriterias = (payload) => ({
  type: SET_SORT_CRITERIAS,
  payload,
});

export const searchVendorSuccess = (payload) => ({
  type: SEARCH_VENDORS_SUCCESS,
  payload,
});

export const searchVendorFailed = (payload) => ({
  type: SEARCH_VENDORS_FAILED,
  payload,
});
