import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Tabs } from 'antd';

import { fetchLocations, setSearchLocations } from './offices-actions';
import { officesTabsConfig } from './offices-tabs-config';
import { getIsLoading, getLocationsTotal, getSearch } from './offices-selectors';
import { getReadTabsPermissions, getWriteTabsPermissions } from '../../store/user/user-reducer';

import BaseGrid from '../grid/BaseGrid';
import SearchLocations from '../header/search/SearchLocations';
import MapWrapper from '../map/MapWrapper';

const { TabPane } = Tabs;

function Offices() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('');
  const [isAllSelected, setIsAllSelected] = useState(false);

  const total = useSelector(getLocationsTotal);
  const search = useSelector(getSearch);
  const loading = useSelector(getIsLoading);
  const isReadAllowed = useSelector(getReadTabsPermissions);
  const isWriteAllowed = useSelector(getWriteTabsPermissions);

  useEffect(() => {
    dispatch(fetchLocations(search));
  }, [search]);

  useEffect(() => {
    setActiveTab(defaultActiveKey());
  }, []);

  useEffect(() => {
    if (activeTab !== 'map') {
      dispatch(setSearchLocations({ activeTab }));
    }
  }, [activeTab]);

  const defaultActiveKey = () => {
    const tabKeys = Object.keys(isReadAllowed)
      .map((key) => [key, isReadAllowed[key] || isWriteAllowed[key]])
      .filter(([_, value]) => value)
      .map(([key, _]) => key);

    return tabKeys[0];
  };

  const onPageChange = (searchData) => {
    dispatch(setSearchLocations(searchData));
  };

  return (
    <>
      <SearchLocations toggleIsAllSelected={(val) => setIsAllSelected(val)} isColored={activeTab === 'map'} />
      <Tabs
        className={'common-tabs'}
        activeKey={activeTab}
        onChange={(activeKey) => setActiveTab(activeKey)}
        destroyInactiveTabPane
      >
        {Object.entries(officesTabsConfig(null)).map(([key, tab]) => {
          return (
            <TabPane tab={tab.title.toUpperCase()} key={key} disabled={!isReadAllowed[key] && !isWriteAllowed[key]}>
              <TabContainer>
                {tab.title === 'map' ? (
                  <MapWrapper />
                ) : (
                  <BaseGrid
                    id={key}
                    activeTab={activeTab}
                    total={total}
                    isAllSelected={isAllSelected}
                    toggleIsAllSelected={(val) => setIsAllSelected(val)}
                    search={search}
                    setSearch={onPageChange}
                    loading={loading}
                  />
                )}
              </TabContainer>
            </TabPane>
          );
        })}
      </Tabs>
    </>
  );
}

export default Offices;

const TabContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;
