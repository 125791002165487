import { LocationDto } from '../../models/location-dto';

export const FETCH_LOCATIONS = `FETCH_LOCATIONS`;
export const FETCH_LOCATIONS_SUCCESS = `FETCH_LOCATIONS_SUCCESS`;
export const FETCH_LOCATIONS_FAILED = `FETCH_LOCATIONS_FAILED`;
export const CREATE_LOCATION = `CREATE_LOCATION`;
export const CREATE_LOCATION_SUCCESS = `CREATE_LOCATION_SUCCESS`;
export const CREATE_LOCATION_FAILED = `CREATE_LOCATION_FAILED`;
export const DELETE_LOCATION = `DELETE_LOCATION`;
export const DELETE_LOCATION_SUCCESS = `DELETE_LOCATION_SUCCESS`;
export const DELETE_LOCATION_FAILED = `DELETE_LOCATION_FAILED`;
export const UPDATE_LOCATION = `UPDATE_LOCATION`;
export const UPDATE_LOCATION_SUCCESS = `UPDATE_LOCATION_SUCCESS`;
export const UPDATE_LOCATION_FAILED = `UPDATE_LOCATION_FAILED`;

export const FETCH_REGIONS = `FETCH_REGIONS`;
export const FETCH_REGIONS_SUCCESS = `FETCH_REGIONS_SUCCESS`;
export const FETCH_DISTRICTS = `FETCH_DISTRICTS`;
export const SEARCH_DISTRICTS = `SEARCH_DISTRICTS`;
export const FETCH_DISTRICTS_SUCCESS = `FETCH_DISTRICTS_SUCCESS`;

export const FETCH_OFFICE_LIST = `FETCH_OFFICE_LIST`;
export const CLEAR_OFFICE_LIST = `CLEAR_OFFICE_LIST`;
export const FETCH_OFFICE_LIST_SUCCESS = `FETCH_OFFICE_LIST_SUCCESS`;

export const SET_SEARCH_LOCATIONS = 'SET_SEARCH_LOCATIONS';

export const FETCH_LOCATION_ASSIGNMENT_COUNT = 'FETCH_LOCATION_ASSIGNMENT_COUNT';
export const FETCH_LOCATION_ASSIGNMENT_COUNT_SUCCESS = 'FETCH_LOCATION_ASSIGNMENT_COUNT_SUCCESS';
export const FETCH_LOCATION_ASSIGNMENT_COUNT_FAIL = 'FETCH_LOCATION_ASSIGNMENT_COUNT_FAIL';

export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_FAIL = 'UPDATE_CONTACT_FAIL';

export const SAVE_VENDOR_ACCOUNTS = 'SAVE_VENDOR_ACCOUNTS';
export const SAVE_VENDOR_ACCOUNTS_SUCCESS = 'SAVE_VENDOR_ACCOUNTS_SUCCESS';
export const SAVE_VENDOR_ACCOUNTS_FAIL = 'SAVE_VENDOR_ACCOUNTS_FAIL';

export const FETCH_CONTACTS = 'FETCH_CONTACTS';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_FAIL = 'FETCH_CONTACTS_FAIL';

export const SET_SEARCH_REGION_ID = 'SET_SEARCH_REGION_ID';

export const CREATE_REGION = `CREATE_REGION`;
export const CREATE_REGION_SUCCESS = `CREATE_REGION_SUCCESS`;
export const CREATE_REGION_FAILED = `CREATE_REGION_FAILED`;

export const CREATE_DISTRICT = `CREATE_DISTRICT`;
export const CREATE_DISTRICT_SUCCESS = `CREATE_DISTRICT_SUCCESS`;
export const CREATE_DISTRICT_FAILED = `CREATE_DISTRICT_FAILED`;

export const DELETE_VENDOR = `DELETE_VENDOR`;
export const DELETE_VENDOR_SUCCESS = `DELETE_VENDOR_SUCCESS`;
export const DELETE_VENDOR_FAILED = `DELETE_VENDOR_FAILED`;

export const CREATE_BILLING = 'CREATE_BILLING';
export const CREATE_BILLING_SUCCESS = 'CREATE_BILLING_SUCCESS';
export const CREATE_BILLING_FAILED = 'CREATE_BILLING_FAILED';

export const SET_SELECTED_STAFF = 'SET_SELECTED_STAFF';
export const TOGGLE_SELECTED_LOCATIONS = 'TOGGLE_SELECTED_LOCATIONS';
export const REGION_DISTRICT_BULK_UPDATE = 'REGION_DISTRICT_BULK_UPDATE';
export const REGION_DISTRICT_BULK_UPDATE_SUCCESS = 'REGION_DISTRICT_BULK_UPDATE_SUCCESS';
export const REGION_DISTRICT_BULK_UPDATE_FAILED = 'REGION_DISTRICT_BULK_UPDATE_FAILED';
export const REGION_DISTRICT_BULK_UPDATE_STAFF = 'REGION_DISTRICT_BULK_UPDATE_STAFF';
export const REGION_DISTRICT_BULK_UPDATE_STAFF_WARN = 'REGION_DISTRICT_BULK_UPDATE_STAFF_WARN';
export const UNASSIGN_STAFF = 'UNASSIGN_STAFF';

export const FETCH_LOCATIONS_FOR_SEARCH = 'FETCH_LOCATIONS_FOR_SEARCH';
export const CLEAR_LOCATIONS_FOR_SEARCH = 'CLEAR_LOCATIONS_FOR_SEARCH';
export const FETCH_LOCATIONS_FOR_SEARCH_SUCCESS = 'FETCH_LOCATIONS_FOR_SEARCH_SUCCESS';

export const UPDATE_CONTACT_SPECIALTY = 'UPDATE_CONTACT_SPECIALTY';

export const CLOSE_DRAWER = 'CLOSE_DRAWER';

export const updateContact = (payload) => ({
  type: UPDATE_CONTACT,
  payload,
});

export const updateContactSuccess = (payload) => ({
  type: UPDATE_CONTACT_SUCCESS,
  payload,
});

export const updateContactFail = (payload) => ({
  type: UPDATE_CONTACT_FAIL,
  payload,
});

export const fetchContacts = (payload) => ({
  type: FETCH_CONTACTS,
  payload,
});

export const fetchContactsSuccess = (payload) => ({
  type: FETCH_CONTACTS_SUCCESS,
  payload,
});

export const fetchContactsFail = (payload) => ({
  type: FETCH_CONTACTS_FAIL,
  payload,
});

export const fetchLocationAssignmentCount = (id) => ({
  type: FETCH_LOCATION_ASSIGNMENT_COUNT,
  id,
});

export const fetchLocationAssignmentCountSuccess = (payload) => ({
  type: FETCH_LOCATION_ASSIGNMENT_COUNT_SUCCESS,
  payload,
});

export const fetchLocationAssignmentCountFail = (payload) => ({
  type: FETCH_LOCATION_ASSIGNMENT_COUNT_FAIL,
  payload,
});

export const setSearchLocations = (payload: any) => ({
  type: SET_SEARCH_LOCATIONS,
  payload,
});

export const setSearchRegionId = (payload: any) => ({
  type: SET_SEARCH_REGION_ID,
  payload,
});

export const fetchOfficeList = (payload: any) => ({
  type: FETCH_OFFICE_LIST,
  payload,
});

export const clearOfficeList = () => ({
  type: CLEAR_OFFICE_LIST,
});

export const fetchOfficeListSuccess = (payload: any) => ({
  type: FETCH_OFFICE_LIST_SUCCESS,
  payload,
});

export const fetchRegions = (payload?: any) => ({
  type: FETCH_REGIONS,
  ...(payload && { payload }),
});

export const fetchRegionsSuccess = (payload: any) => ({
  type: FETCH_REGIONS_SUCCESS,
  payload,
});

export const fetchDistricts = (payload?: any) => ({
  type: FETCH_DISTRICTS,
  ...(payload && { payload }),
});

export const searchDistrictsByArea = (payload?: any) => ({
  type: SEARCH_DISTRICTS,
  payload,
});

export const fetchDistrictsSuccess = (payload: any) => ({
  type: FETCH_DISTRICTS_SUCCESS,
  payload,
});

export const fetchLocations = (payload: any) => ({
  type: FETCH_LOCATIONS,
  payload,
});

export const fetchLocationsSuccess = (payload: any) => ({
  type: FETCH_LOCATIONS_SUCCESS,
  payload,
});

export const fetchLocationsFailed = (payload: any) => ({
  type: FETCH_LOCATIONS_FAILED,
  payload,
});

export const createLocation = (payload: any) => ({
  type: CREATE_LOCATION,
  payload,
});

export const createLocationSuccess = (payload: any) => ({
  type: CREATE_LOCATION_SUCCESS,
  payload,
});

export const createLocationFailed = (payload: any) => ({
  type: CREATE_LOCATION_FAILED,
  payload,
});

export const deleteLocation = (payload: LocationDto) => ({
  type: DELETE_LOCATION,
  payload,
});

export const deleteLocationSuccess = (payload: any) => ({
  type: DELETE_LOCATION_SUCCESS,
  payload,
});

export const deleteLocationFailed = (payload: any) => ({
  type: DELETE_LOCATION_FAILED,
  payload,
});
export const updateLocation = (payload: LocationDto) => ({
  type: UPDATE_LOCATION,
  payload,
});

export const updateLocationSuccess = (payload: any) => ({
  type: UPDATE_LOCATION_SUCCESS,
  payload,
});

export const updateLocationFailed = (payload: any) => ({
  type: UPDATE_LOCATION_FAILED,
  payload,
});

export const createRegion = (payload: any) => ({
  type: CREATE_REGION,
  payload,
});

export const createRegionSuccess = (payload: any) => ({
  type: CREATE_REGION_SUCCESS,
  payload,
});

export const createRegionFailed = (payload: any) => ({
  type: CREATE_REGION_FAILED,
  payload,
});

export const createDistrict = (payload: any) => ({
  type: CREATE_DISTRICT,
  payload,
});

export const createDistrictSuccess = (payload: any) => ({
  type: CREATE_DISTRICT_SUCCESS,
  payload,
});

export const createDistrictFailed = (payload: any) => ({
  type: CREATE_DISTRICT_FAILED,
  payload,
});

export const createBilling = (payload) => ({
  type: CREATE_BILLING,
  payload,
});

export const createBillingSuccess = (payload) => ({
  type: CREATE_BILLING_SUCCESS,
  payload,
});

export const createBillingFailed = (payload) => ({
  type: CREATE_BILLING_FAILED,
  payload,
});

export const setSelectedStaff = (payload) => ({
  type: SET_SELECTED_STAFF,
  payload,
});

export const toggleSelectedLocations = (payload) => ({
  type: TOGGLE_SELECTED_LOCATIONS,
  payload,
});

export const regionDistrictBulkUpdate = (payload) => ({
  type: REGION_DISTRICT_BULK_UPDATE,
  payload,
});

export const regionDistrictBulkUpdateSuccess = (payload) => ({
  type: REGION_DISTRICT_BULK_UPDATE_SUCCESS,
  payload,
});

export const regionDistrictBulkUpdateFailed = (payload) => ({
  type: REGION_DISTRICT_BULK_UPDATE_FAILED,
  payload,
});

export const regionDistrictBulkUpdateStaff = (payload) => ({
  type: REGION_DISTRICT_BULK_UPDATE_STAFF,
  payload,
});

export const regionDistrictBulkUpdateStaffWarn = (payload) => ({
  type: REGION_DISTRICT_BULK_UPDATE_STAFF_WARN,
  payload,
});

export const unassignStuff = (payload) => ({
  type: UNASSIGN_STAFF,
  payload,
});

export const fetchLocationsForSearch = (payload: any) => ({
  type: FETCH_LOCATIONS_FOR_SEARCH,
  payload,
});

export const clearLocationsForSearch = () => ({
  type: CLEAR_LOCATIONS_FOR_SEARCH,
});

export const fetchLocationsForSearchSuccess = (payload: any) => ({
  type: FETCH_LOCATIONS_FOR_SEARCH_SUCCESS,
  payload,
});

export const updateContactSpecialty = (payload: any) => ({
  type: UPDATE_CONTACT_SPECIALTY,
  payload,
});

export const deleteVendor = (payload: any) => ({
  type: DELETE_VENDOR,
  payload,
});

export const deleteVendorSuccess = (payload: any) => ({
  type: DELETE_VENDOR_SUCCESS,
  payload,
});

export const deleteVendorFailed = (payload: any) => ({
  type: DELETE_VENDOR_FAILED,
  payload,
});

export const saveVendorAccount = (payload) => ({
  type: SAVE_VENDOR_ACCOUNTS,
  payload,
});

export const saveVendorAccountSuccess = (payload) => ({
  type: SAVE_VENDOR_ACCOUNTS_SUCCESS,
  payload,
});

export const saveVendorAccountFail = (payload) => ({
  type: SAVE_VENDOR_ACCOUNTS_FAIL,
  payload,
});

export const closeTabDrawer = () => {
  return {
    type: CLOSE_DRAWER,
  };
};
