import React from 'react';
import { AuthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import Export from './export/Export';
import Import from './import/Import';
import NavbarMenu from '../navbar/NavbarMenu';
import { useSelector } from 'react-redux';
import { getIsAccessDenied } from '../../store/user/user-reducer';
import { StyledHeader, StyledProfile } from '@med-fe/ui';
import ProfilePanel from './profile-panel/ProfilePanel';
import { useLocation } from 'react-router-dom';

export default function Header({ routes }) {
  const { pathname } = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const isAccessDenied = useSelector(getIsAccessDenied);

  const profilePanel = ({ accounts, onSignHandler }) => <ProfilePanel user={accounts[0]} onSignOut={onSignHandler} />;

  return (
    <StyledHeader isAuthenticated={isAuthenticated}>
      <AuthenticatedTemplate>
        {!isAccessDenied && (
          <>
            <NavbarMenu routes={routes} />
            {!pathname.includes('audit') && !pathname.includes('dashboard') && !pathname.includes('vendor') && (
              <Import />
            )}
            {!pathname.includes('dashboard') && <Export />}
          </>
        )}
      </AuthenticatedTemplate>
      {isAuthenticated && <StyledProfile profilePanel={profilePanel} />}
    </StyledHeader>
  );
}
