export const profileConfig = {
  externalLocationName: {
    label: 'External Location Name',
    type: 'text',
  },
  locatedIn: {
    label: 'Located In',
    type: 'text',
  },
  openingDate: {
    label: 'Open Date',
    type: 'date',
  },
  officeQuickCode: {
    label: 'Quick Code',
    type: 'text',
  },
  email: {
    label: 'Email',
    type: 'text',
  },
  odOnCall: {
    label: 'Od on-call',
    type: 'text',
  },
  squareFootage: {
    label: 'Square Footage',
    type: 'text',
  },
  leaseExpiration: {
    label: 'Lease Expiration',
    type: 'date',
  },
  unified: {
    label: 'Unified',
    type: 'checkbox',
  },
  labOnsite: {
    label: 'Lab onsite',
    type: 'checkbox',
  },
  modifiedDate: {
    label: 'Last Modified',
    type: 'text',
  },
  pos: {
    label: 'POS',
    isVisible: true,
    type: 'select',
  },
  encompassLaunchDate: {
    label: 'Encompass Launch Date',
    isVisible: true,
    type: 'date',
    rules: [{ required: true, message: 'Please select Encompass Launch Date' }],
  },
};
