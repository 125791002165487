import { get } from 'lodash';
import { DayOfWeek } from '../../models/day-enum';
import { ILocationsState, ISearchLocations, LOCATIONS_STATE_KEY } from './offices-reducer';
import { ManagersEnum } from '../collapsible-panel/managers.enum';
import { PsPartnersEnum } from '../collapsible-panel/ps-partners.enum';
import { HrPartnersEnum } from '../collapsible-panel/hr-partners.enum';
import moment from 'moment';
import { mapSchedulesToForm } from '../../common/helpers/mappers';
import { ScheduleDto } from '../../models/schedule-dto';

export const getStub = () => [];

export const getIsLoading = (state: any) => state[LOCATIONS_STATE_KEY].loading;
export const getIsLoadingLocationNumbers = (state: any) => state[LOCATIONS_STATE_KEY].loadingLocationNumbers;
export const getError = (state: any) => state[LOCATIONS_STATE_KEY].error;
export const getLocations = (state: any) => state[LOCATIONS_STATE_KEY].locations;
export const getLocationById = (state: any, id: string) =>
  state[LOCATIONS_STATE_KEY].locations.contents.find((loc) => loc.id === id);
export const getRegions = (state: any) => state[LOCATIONS_STATE_KEY].regions;
export const getDistricts = (state: any) => state[LOCATIONS_STATE_KEY].districts;
export const getContacts = (state: any) => state[LOCATIONS_STATE_KEY].contacts;
export const getSearch = (state: ILocationsState): ISearchLocations => state[LOCATIONS_STATE_KEY].search;
export const getSearchRegionId = (state: any) => state[LOCATIONS_STATE_KEY].searchRegionId;
export const getAssignmentCount = (state: any) => state[LOCATIONS_STATE_KEY].assignmentCount;
export const getSelectedStaff = (state: any) => state[LOCATIONS_STATE_KEY].selectedStaff;
export const getSelectedLocations = (state: any) => state[LOCATIONS_STATE_KEY].selectedLocations;
export const getOfficeList = (state: any) => state[LOCATIONS_STATE_KEY].officeList;
export const getLocationsTotal = (state: any) => getLocations(state).total;
export const getLocationsForSearch = (state: any) => state[LOCATIONS_STATE_KEY].locationsForSearch;
export const getProServices = (state: any) => state[LOCATIONS_STATE_KEY].proServices;
export const getSpecialties = (state: any) => state[LOCATIONS_STATE_KEY].specialties;
export const getVendors = (state: any) => state[LOCATIONS_STATE_KEY].vendors;
export const getCloseDrawer = (state: any) => state[LOCATIONS_STATE_KEY].closeDrawer;
export const getLocationsForGrid = (state: any) =>
  getLocations(state).contents.map((content: any) => {
    let addressResult = '';
    const address = get(content, 'address', null);

    if (address) {
      const { id, lat, lng, phone, fax, ...addressFields } = get(content, 'address', {});
      addressResult = [
        ...Object.values(addressFields).filter((field) => !!field),
        ...(phone ? [`P:${phone}`] : []),
        ...(fax ? [`F:${fax}`] : []),
      ].join(', ');
    }

    return {
      key: content.id,
      locationName: content.locationName,
      locationNumber: content.locationNumber,
      region: get(content, 'region.name', null),
      regionId: get(content, 'region.id', null),
      district: get(content, 'district.name', null),
      districtId: get(content, 'district.id', null),
      county: content.county,
      address: addressResult,
      status: content.status,
      directDial: content.directDial,
      timezone: content.timezone,
      openingDate: content.openingDate,
    };
  });
export const getProfileForGrid = (state: any) =>
  getLocations(state).contents.map((content: any) => ({
    key: content.id,
    locationName: content.locationName,
    locationNumber: content.locationNumber,
    openingDate: content.openingDate,
    officeQuickCode: content.officeQuickCode,
    email: content.email,
    odOnCall: content.odOnCall,
    squareFootage: content.squareFootage,
    leaseExpiration: content.leaseExpiration,
    unified: content.unified,
    labOnsite: content.labOnsite,
    status: content.status,
    modifiedDate: moment(content.modifiedDate).format('YYYY-MM-DD HH:mm:ss'),
    externalLocationName: content.externalLocationName,
    locatedIn: content.locatedIn,
    pos: content.pos,
    encompassLaunchDate: content.encompassLaunchDate,
  }));
export const gerProfileForLocation = (state: any, id: string) => ({
  ...getProfileForGrid(state).find((profile) => profile.key === id),
});
export const getFinanceForGrid = (state: any) =>
  getLocations(state).contents.map((content: any) => {
    return {
      key: content.id,
      locationName: content.locationName,
      locationNumber: content.locationNumber,
      legalName: content.legalName,
      taxid: content.taxid,
      merchantBankAccountNumber: get(content, 'billing.merchantBankAccountNumber', null),
      drOfRecord: get(content, 'billing.drOfRecord', null),
      oracleLegalEntityNum: get(content, 'billing.oracleLegalEntityNum', null),
      oracleOfficeNum: get(content, 'billing.oracleOfficeNum', null),
      depositoryBankAccount: get(content, 'billing.depositoryBankAccount', null),
      worldpayMerchantId: get(content, 'billing.worldpayMerchantId', null),
      careCreditMerchantId: get(content, 'billing.careCreditMerchantId', null),
      worldpaySerialNumber: get(content, 'billing.worldpaySerialNumber', null),
      vintageName: content.vintageName,
      mergedInto: get(content, 'mergedInto.locationNumber', null),
      closedDate: content.closedDate,
      status: content.status,
      depositMethod: content.depositMethod,
      locationCategory: content.locationCategory,
      sameOfficeSales: content?.sameOfficeSales,
      sameOfficeChangeDate: content?.sameOfficeChangeDate,
      acuityLogicLaunchDate: content.acuityLogicLaunchDate,
      newOfficeYear: content.newOfficeYear,
      acquisitionYear: content.acquisitionYear,
      jointVentureYear: content.jointVentureYear,
      preAcquisitionAnnualRevenue: content.preAcquisitionAnnualRevenue,
    };
  });
export const getBillingInfoForLocation = (state: any, id: string) => {
  let result = {};
  const loc = getLocationById(state, id);
  if (loc) {
    result = {
      legalName: loc.legalName,
      taxid: loc.taxid,
      closedDate: loc.closedDate,
      vintageName: loc.vintageName,
      mergedInto: { id: loc.mergedInto?.id, name: loc.mergedInto?.locationNumber },
      depositMethod: loc.depositMethod,
    };
  }
  return result;
};
export const getAddressForLocation = (state: any, id: string): any => {
  let result = {};
  const loc = getLocationById(state, id);

  if (loc) {
    result = {
      ...get(loc, 'address', {
        city: null,
        fax: null,
        id: null,
        phone: null,
        state: null,
        street: null,
        street2: null,
        zip: null,
      }),
      pos: get(loc, 'pos', null),
      locationCategory: get(loc, 'locationCategory', null),
      encompassLaunchDate: get(loc, 'encompassLaunchDate', null),
    };
  }
  return result;
};
export const getBillingAccount = (state: any, id: string) => {
  let result = {};
  const loc = getLocationById(state, id);

  if (loc) {
    result = {
      id: get(loc, 'billing.id', null),
      merchantBankAccountNumber: get(loc, 'billing.merchantBankAccountNumber', null),
      drOfRecord: get(loc, 'billing.drOfRecord', null),
      oracleLegalEntityNum: get(loc, 'billing.oracleLegalEntityNum', null),
      oracleOfficeNum: get(loc, 'billing.oracleOfficeNum', null),
      depositoryBankAccount: get(loc, 'billing.depositoryBankAccount', null),
      worldpayMerchantId: get(loc, 'billing.worldpayMerchantId', null),
      careCreditMerchantId: get(loc, 'billing.careCreditMerchantId', null),
      worldpaySerialNumber: get(loc, 'billing.worldpaySerialNumber', null),
      integration: get(loc, 'integration', null),
      sameOfficeSales: get(loc, 'sameOfficeSales', null),
      sameOfficeChangeDate: get(loc, 'sameOfficeChangeDate', null),
      acuityLogicLaunchDate: get(loc, 'acuityLogicLaunchDate', null),
      pos: get(loc, 'pos', null),
      locationCategory: get(loc, 'locationCategory', null),
      encompassLaunchDate: get(loc, 'encompassLaunchDate', null),
      newOfficeYear: get(loc, 'newOfficeYear', null),
      acquisitionYear: get(loc, 'acquisitionYear', null),
      jointVentureYear: get(loc, 'jointVentureYear', null),
      preAcquisitionAnnualRevenue: get(loc, 'preAcquisitionAnnualRevenue', null),
      vintageName: get(loc, 'vintageName', null),
    };
  }
  return result;
};
export const getMarketingForGrid = (state: any) =>
  getLocations(state).contents.map((content: any) => ({
    key: content.id,
    locationName: content.locationName,
    locationNumber: content.locationNumber,
    branding: content.branding,
    mediaMarket: content.mediaMarket,
    msa: content.msa,
    top5zip: content.top5zip,
    digitalMetroCategory: content.digitalMetroCategory,
    status: content.status,
  }));
export const getMarketingForLocation = (state: any, id: string) => ({
  ...getMarketingForGrid(state).find((marketing) => marketing.key === id),
});
export const getScheduleForGrid = (state: any) =>
  getLocations(state).contents.map((content: any) => {
    const schedules: ScheduleDto[] = get(content, 'schedules', []);
    const effectiveDate = schedules.reduce((result, day) => {
      return !result ? day.validFrom : moment(day.validFrom).isAfter(result) ? day.validFrom : result;
    }, '');

    return schedules.reduce(
      (acc, item) => {
        const day = DayOfWeek[item.dayOfWeek as keyof typeof DayOfWeek];
        return {
          ...acc,
          [day]: `${item.open}-${item.close}`,
        };
      },
      {
        key: content.id,
        locationName: content.locationName,
        locationNumber: content.locationNumber,
        status: content.status,
        timezone: content.timezone,
        effectiveDate,
      }
    );
  });
export const getMvcForGrid = (state: any) =>
  getLocations(state).contents.map((content: any) => ({
    key: content.id,
    locationName: content.locationName,
    locationNumber: content.locationNumber,
    medicalRecordsType: content.medicalRecordsType,
    acuityLogicLaunchDate: content.acuityLogicLaunchDate,
    cliaExpiration: content.cliaExpiration,
    professionalServicesAnalyst: get(
      content.staff?.find((item) => item.job === 'PSA'),
      'name',
      null
    ),
    hrBusinessPartner: get(
      content.staff?.find((item) => item.job === 'HRBP'),
      'name',
      null
    ),
    medicaidLocation: content.medicaidLocation,
    davisLocation: content.davisLocation,
    // vspLocation: content.vspLocation,
    status: content.status,
    groupNpi: content.groupNpi,
    epId: content.epId,
    bcbs: content.bcbs,
    medicare: content.medicare,
    tricare: content.tricare,
    legacyArAcq: content.legacyArAcq,
    legacyArClosed: content.legacyArClosed,
    transitionArClosed: content.transitionArClosed,
    naLocation: content.naLocation,
    medicaidIntention: content.medicaidIntention,
    vspStatus: content.vspStatus,
    vintageName: content.vintageName,
    medicareDme: content.medicareDme,
  }));
export const getMvcForLocation = (state: any, id: string) => ({
  ...getMvcForGrid(state).find((mvc) => mvc.key === id),
});
export const getBillingForGrid = (state: any) =>
  getLocations(state).contents.map((content: any) => {
    let billingAddress = '';
    const address = get(content, 'address', null);

    if (address) {
      const { id, ...addressFields } = get(content, 'address', {});
      billingAddress = Object.values(addressFields).join(', ');
    }
    return {
      key: content.id,
      merchantBankRouting: get(content, 'billing.merchantBankRouting', null),
      merchantBankAccountNumber: get(content, 'billing.merchantBankAccountNumber', null),
      drOfRecord: get(content, 'billing.drOfRecord', null),
      oracleLegalEntityNum: get(content, 'billing.oracleLegalEntityNum', null),
      oracleOfficeNum: get(content, 'billing.oracleOfficeNum', null),
      depositoryBankAccount: get(content, 'billing.depositoryBankAccount', null),
      worldpayMerchantId: get(content, 'billing.worldpayMerchantId', null),
      careCreditMerchantId: get(content, 'billing.careCreditMerchantId', null),
      worldpaySerialNumber: get(content, 'billing.worldpaySerialNumber', null),
      action: get(content, 'billing.action', null),
    };
  });

export const getManagersForLocation = (state: any, id: string) => {
  let result = [];
  const loc = getLocationById(state, id);

  if (loc) {
    result = loc.staff.filter((item) => Object.keys(ManagersEnum).includes(item['job']));
  }

  return result;
};

export const getDoctorsForLocation = (state: any, id: string) => {
  let result = [];
  const loc = getLocationById(state, id);

  if (loc) {
    result = loc.staff.filter((doc) => doc.job === 'OD');
  }

  return result;
};

export const getPsPartnersForLocation = (state: any, id: string) => {
  let result = [];
  const loc = getLocationById(state, id);

  if (loc) {
    result = loc.staff.filter((item) => Object.keys(PsPartnersEnum).includes(item['job']));
  }

  return result;
};

export const getHrPartnersForLocation = (state: any, id: string) => {
  let result = [];
  const loc = getLocationById(state, id);

  if (loc) {
    result = loc.staff.filter((item) => Object.keys(HrPartnersEnum).includes(item['job']));
  }

  return result;
};

export interface ILocationDetails {
  district?: string;
  region?: string;
  county?: string;
  status?: string;
  // address
  id?: number;
  street?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  fax?: string;
}

export const getLocationDetails = (state: any, id: string): ILocationDetails | null => {
  const loc = getLocationById(state, id);
  let result: ILocationDetails | null = null;
  if (loc) {
    result = {
      ...get(loc, 'address', null),
      district: get(loc, 'district.id', null),
      region: get(loc, 'region.id', null),
      county: get(loc, 'county', null),
      status: get(loc, 'status', null),
      timezone: get(loc, 'timezone', null),
      directDial: get(loc, 'directDial', null),
      locationName: loc.locationName,
      openingDate: get(loc, 'openingDate', null),
      pos: get(loc, 'pos', null),
      locationCategory: get(loc, 'locationCategory', null),
      encompassLaunchDate: get(loc, 'encompassLaunchDate', null),
    };
  }
  return result;
};

export const getSchedulesForLocation = (state: any, id: string) =>
  mapSchedulesToForm(getLocationById(state, id).schedules);

export const getTimezoneForLocation = (state: any, id: string) => getLocationById(state, id).timezone;

export const getProServicesForGrid = (state: any) =>
  getLocations(state).contents.map((content: any) => {
    return {
      key: content.id,
      locationName: content.locationName,
      locationNumber: content.locationNumber,
      odOnCall: content.odOnCall,
      onCallPhone: content.onCallPhone,
      status: content.status,
      preceptorLocation: content.preceptorLocation,
      dryEyeProgram: content.dryEyeProgram,
      locationEquipments: content.locationEquipments?.map(({ id, fullName }) => id),
    };
  });

export const getProServiceForLocation = (state: any, id: string) => ({
  ...getProServicesForGrid(state).find((proService) => proService.key === id),
});

export const getItServicesForGrid = (state: any) =>
  getLocations(state).contents.map((content: any) => {
    return {
      key: content.id,
      locationName: content.locationName,
      locationNumber: content.locationNumber,
      status: content?.status,

      mspAccountNumber: get(content, 'locationInfra[0].mspAccountNumber', null),
      networkStandardized: get(content, 'locationInfra[0].networkStandardized', null) ? 'Yes' : 'No',
      tier: get(content, 'locationInfra[0].tier', null),
      provider: get(content, 'locationInfra[0].provider', null),
      speed: get(content, 'locationInfra[0].speed', null),
      circuitType: get(content, 'locationInfra[0].circuitType', null),
      mspStatus: get(content, 'locationInfra[0].mspStatus', null),
      completionDate: get(content, 'locationInfra[0].completionDate', null),
      notes: get(content, 'locationInfra[0].notes', null),
      providerSecondary: get(content, 'locationInfra[0].providerSecondary', null),
      speedSecondary: get(content, 'locationInfra[0].speedSecondary', null),
      typeSecondary: get(content, 'locationInfra[0].typeSecondary', null),

      routerModel: get(content, 'locationInfra[0].routerModel', null),
      routerModelCount: get(content, 'locationInfra[0].routerModelCount', null),
      switches: get(content, 'locationInfra[0].switches', null),
      waps: get(content, 'locationInfra[0].waps', null),
      vvxPhones: get(content, 'locationInfra[0].vvxPhones', null),
      cordlessPhones: get(content, 'locationInfra[0].cordlessPhones', null),

      lanSubnet: get(content, 'locationInfra[0].lanSubnet', null),
      gatewayDHCP: get(content, 'locationInfra[0].gatewayDHCP', null),
      range: get(content, 'locationInfra[0].range', null),

      staticIP: get(content, 'locationInfra[0].staticIP', null),
      gateway: get(content, 'locationInfra[0].gateway', null),
      dnsPrimary: get(content, 'locationInfra[0].dnsPrimary', null),
      dnsSecondary: get(content, 'locationInfra[0].dnsSecondary', null),
      subnetMask: get(content, 'locationInfra[0].subnetMask', null),
    };
  });

export const getItDetailsForLocation = (state: any, id: string): any => {
  let result = {};
  const content = getLocationById(state, id);

  if (content) {
    result = {
      key: content.id,
      id: get(content, 'locationInfra[0].id', null),
      locationId: content.id,

      mspAccountNumber: get(content, 'locationInfra[0].mspAccountNumber', null),
      networkStandardized: get(content, 'locationInfra[0].networkStandardized', null),
      tier: get(content, 'locationInfra[0].tier', null),
    };
  }

  return result;
};

export const getItAccessDetailsForLocation = (state: any, id: string): any => {
  let result = {};
  const content = getLocationById(state, id);

  if (content) {
    result = {
      key: content.id,

      provider: get(content, 'locationInfra[0].provider', null),
      speed: get(content, 'locationInfra[0].speed', null),
      circuitType: get(content, 'locationInfra[0].circuitType', null),
      mspStatus: get(content, 'locationInfra[0].mspStatus', null),
      completionDate: get(content, 'locationInfra[0].completionDate', null),
      notes: get(content, 'locationInfra[0].notes', null),
      providerSecondary: get(content, 'locationInfra[0].providerSecondary', null),
      speedSecondary: get(content, 'locationInfra[0].speedSecondary', null),
      typeSecondary: get(content, 'locationInfra[0].typeSecondary', null),
    };
  }

  return result;
};

export const getItEquipmentDetailsForLocation = (state: any, id: string): any => {
  let result = {};
  const content = getLocationById(state, id);

  if (content) {
    result = {
      key: content.id,
      routerModel: get(content, 'locationInfra[0].routerModel', null),
      routerModelCount: get(content, 'locationInfra[0].routerModelCount', null),
      switches: get(content, 'locationInfra[0].switches', null),
      waps: get(content, 'locationInfra[0].waps', null),
      vvxPhones: get(content, 'locationInfra[0].vvxPhones', null),
      cordlessPhones: get(content, 'locationInfra[0].cordlessPhones', null),
    };
  }

  return result;
};

export const getItLanIPDetailsForLocation = (state: any, id: string): any => {
  let result = {};
  const content = getLocationById(state, id);

  if (content) {
    result = {
      key: content.id,
      lanSubnet: get(content, 'locationInfra[0].lanSubnet', null),
      gatewayDHCP: get(content, 'locationInfra[0].gatewayDHCP', null),
      range: get(content, 'locationInfra[0].range', null),
    };
  }

  return result;
};
export const getItPrimaryCircuitDetailsForLocation = (state: any, id: string): any => {
  let result = {};
  const content = getLocationById(state, id);

  if (content) {
    result = {
      key: content.id,
      staticIP: get(content, 'locationInfra[0].staticIP', null),
      gateway: get(content, 'locationInfra[0].gateway', null),
      dnsPrimary: get(content, 'locationInfra[0].dnsPrimary', null),
      dnsSecondary: get(content, 'locationInfra[0].dnsSecondary', null),
      subnetMask: get(content, 'locationInfra[0].subnetMask', null),
    };
  }

  return result;
};

export const getVehForLocation = (state: any, id: string): any => {
  let result = {};
  const content = getLocationById(state, id);

  const vehDoctor = content.staff.find((item) => item.job === 'TELE');

  if (content) {
    result = {
      key: content.id,
      vehId: get(content, 'locationVeh[0].id', null),
      vehStatus: get(content, 'locationVeh[0].vehStatus', null),
      coverageModel: get(content, 'locationVeh[0].coverageModel', null),
      equipment: get(content, 'locationVeh[0].equipment', null),
      numberOfEquippedPretest: get(content, 'locationVeh[0].numberOfEquippedPretest', null),
      numberOfEquippedLanes: get(content, 'locationVeh[0].numberOfEquippedLanes', null),
      vehGoLive: get(content, 'locationVeh[0].vehGoLive', null),
      vehLastDay: get(content, 'locationVeh[0].vehLastDay', null),
      callCenter: get(content, 'locationVeh[0].callCenter', null),
      pxpLaunch: get(content, 'locationVeh[0].pxpLaunch', null),
      doctorNetwork: get(content, 'locationVeh[0].doctorNetwork', null),
      teleOptometry: get(content, 'teleOptometry', null),
      vehSystem: get(content, 'locationVeh[0].vehSystem', null),
      ...(vehDoctor && { doctor: vehDoctor }),
    };
  }

  return result;
};

export const getVehForGrid = (state: any) =>
  getLocations(state).contents.map((content: any) => {
    const vehDoctor = content.staff?.find((item) => item.job === 'TELE');

    return {
      key: content.id,
      locationName: content.locationName,
      locationNumber: content.locationNumber,
      status: content?.status,

      vehStatus: get(content, 'locationVeh[0].vehStatus', null),
      coverageModel: get(content, 'locationVeh[0].coverageModel', null),
      equipment: get(content, 'locationVeh[0].equipment', null),
      numberOfEquippedPretest: get(content, 'locationVeh[0].numberOfEquippedPretest', null),
      numberOfEquippedLanes: get(content, 'locationVeh[0].numberOfEquippedLanes', null),
      vehGoLive: get(content, 'locationVeh[0].vehGoLive', null),
      vehLastDay: get(content, 'locationVeh[0].vehLastDay', null),
      callCenter: get(content, 'locationVeh[0].callCenter', null),
      pxpLaunch: get(content, 'locationVeh[0].pxpLaunch', null),
      doctorNetwork: get(content, 'locationVeh[0].doctorNetwork', null),
      teleOptometry: get(content, 'teleOptometry', null),
      vehSystem: get(content, 'locationVeh[0].vehSystem', null),
      ...(vehDoctor && { doctor: vehDoctor.name }),
    };
  });

export const getTelecomForGrid = (state: any) =>
  getLocations(state).contents.map((content: any) => {
    return {
      key: content.id,
      locationName: content.locationName,
      locationNumber: content.locationNumber,
      officeQuickCode: content.officeQuickCode,
      pagesURL: content.pagesURL,
      commCenter: content.commCenter,
      directDial: content.directDial,
      helloAgain: content.helloAgain,
    };
  });

export const getTelecomForLocation = (state: any, id: string): any => {
  let result = {};
  const content = getLocationById(state, id);

  if (content) {
    result = {
      key: content.id,
      officeQuickCode: content.officeQuickCode,
      pagesURL: content.pagesURL,
      commCenter: content.commCenter,
      directDial: content.directDial,
      helloAgain: content.helloAgain,
    };
  }

  return result;
};

export const getSpecialtyForGrid = (state: any) =>
  getLocations(state).contents.map((content: any) => {
    return {
      key: content.id,
      locationName: content.locationName,
      locationNumber: content.locationNumber,
      specialtyContactLenses: content.specialtyContactLenses,
      myopiaControl: content.myopiaControl,
      dryEyeTreatment: content.dryEyeTreatment,
      visionTherapy: content.visionTherapy,
      generalMedicalEyecare: content.generalMedicalEyecare,
      iplTreatment: content.iplTreatment,
      tearCareTreatment: content.tearCareTreatment,
      lipiflowTreatment: content.lipiflowTreatment,
      iluxTreatment: content.iluxTreatment,
    };
  });

export const getSpecialtyForLocation = (state: any, id: string): any => {
  let result = {};
  const content = getLocationById(state, id);

  if (content) {
    result = {
      key: content.id,
      specialtyContactLenses: content.specialtyContactLenses,
      myopiaControl: content.myopiaControl,
      dryEyeTreatment: content.dryEyeTreatment,
      visionTherapy: content.visionTherapy,
      generalMedicalEyecare: content.generalMedicalEyecare,
      iplTreatment: content.iplTreatment,
      tearCareTreatment: content.tearCareTreatment,
      lipiflowTreatment: content.lipiflowTreatment,
      iluxTreatment: content.iluxTreatment,
    };
  }

  return result;
};

export const getVendorForGrid = (state: any) =>
  getLocations(state).contents.map((content: any) => {
    const result = {
      key: content.id,
      locationName: content.locationName,
      locationNumber: content.locationNumber,
    };
    content?.vendorAccounts?.map((item: any) => {
      if (content.locationNumber === item.locationNumber) {
        result[`${item.vendorName}_acc`] = item.accountNumber;
      }
    });

    return result;
  });

export const getVendorForLocation = (state: any, id: string): any => {
  let result: any = { locationId: Number, defaultVendorFalse: [], defaultVendorTrue: [] };
  const content = getLocationById(state, id);
  result.locationId = content.id;
  content?.vendorAccounts?.map((item: any) => {
    if (item && item.isDefault === true && item?.active) {
      result?.defaultVendorTrue?.push({
        id: item.id,
        key: item.vendorId,
        name: item.vendorName,
        accountNumber: item.accountNumber !== 'null' ? item.accountNumber : null,
        isDefault: item.isDefault,
        vendorAccountId: item.vendorAccountId,
      });
    } else if (item && item.isDefault === false && item?.active) {
      result?.defaultVendorFalse?.push({
        id: item.id,
        vendorId: item.vendorId,
        name: item.vendorName,
        accountNumber: item.accountNumber,
        isDefault: item.isDefault,
        vendorAccountId: item.vendorAccountId,
      });
    }
  });
  return result;
};
