import { getSearch } from '../offices/offices-selectors';
import { getSearch as getVendorSearch } from '../vendors/vendors-selectors';
import { getSearchAudit } from '../audit/audit-reducer';
import { call, put, select } from 'redux-saga/effects';
import { getSearchDoctors } from '../doctors/doctors-selectors';
import { api, BASE_URL, showErrorNotification, showSuccessNotification } from '@med-fe/util';
import {
  cleanImportLayout,
  exportFileSuccess,
  importFileFailed,
  importFileSuccess,
  updateValidatedImport,
} from './header-actions';
import { getImportValidationData } from './header-reducer';

export function* exportFileStartWorker({ payload }) {
  try {
    const maxSize = 100000; // according to BE implementation
    let search = {};
    let url = `/${payload.path}/export`;
    if (payload.path === 'locations') {
      url += `/${payload.type}`;
      search = yield select(getSearch);
    } else if (payload.path === 'employees') {
      url += `/${payload.type}`;
      search = yield select(getSearchDoctors);
    } else if (payload.path === 'vendors') {
      search = yield select(getVendorSearch);
    } else {
      url += `/${payload.type}`;
      search = yield select(getSearchAudit);
    }

    yield call(api.downloadFile, url, {
      ...search,
      page: 0,
      size: maxSize,
      type: payload.isTemplate ? 'xlsx' : payload.type,
    });

    yield put(exportFileSuccess());
  } catch (e) {
    yield put(exportFileSuccess());
    showErrorNotification(null, e);
  }
}

export function* importBulkUpdateFileStartWorker({ payload }: any): Generator {
  try {
    const url = '/locations/bulk/upload';
    const formdata = new FormData();
    formdata.append('file', payload.file);
    const data: any = yield call(api.post, url, formdata);
    yield put(importFileSuccess(data));
  } catch (e: any) {
    yield put(importFileFailed(e.response.data.reason));
    showErrorNotification(e.response.data.reason, null, 5);
  }
}

export function* updateValidatedImportStartWorker(): Generator {
  try {
    const url = '/locations/bulk/submit';
    const payload = yield select(getImportValidationData);
    const data: any = yield call(api.post, url, payload);

    yield put(cleanImportLayout());
    showSuccessNotification(data, 3);
  } catch (e: any) {
    yield put(importFileFailed(e.response.data.reason));
    showErrorNotification(e.response.data.reason);
  }
}
